import {Subject, Observable, of} from 'rxjs';
import {catchError, debounceTime, map, switchMap, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

export class FetchData<Input, Output> {
  public input = new Subject<Input>();
  public fetchStarted = new Subject<void>();
  public error = new Subject<HttpErrorResponse>();
  public dataReceived = new Subject<Output>();
  private disposed = new Subject<void>();

  constructor(
    fetchData: (inputData: Input) => Observable<Output>,
    debounceMillis: number = 500
  ) {
    // The input pipe:
    // 1. handles debounce
    // 2. passes the input to the "switchMap", which ensures any prior call is canceled
    // 3. continues until disposed
    this.input.pipe(
      debounceTime(debounceMillis),
      switchMap(inData => {
        this.fetchStarted.next();

        return fetchData(inData).pipe(
          catchError((err: HttpErrorResponse) => {
            this.error.next(err);
            return of(null);
          }),
          map(outData => this.dataReceived.next(outData)),
        );
      }),
      takeUntil(this.disposed)
    )
    .subscribe();
  }

  dispose(): void {
    this.disposed.next();
  }
}
