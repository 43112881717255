import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AccountService } from '../../../configuration-management/services/account.service';
import { FormGroup, FormControl, Validators, Form } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../../../configuration-management/models/user.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SyncService } from '../../../shared/offline/sync-service';
import { UserIdleService } from 'angular-user-idle';
import { storage } from 'src/app/shared/local-storage/local-storage.service';

/** @title Sidenav open & close behavior */
@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {
  currentUser: User;
  @Output() closeComponent = new EventEmitter<boolean>();
  @Input() isDialog: boolean;
  constructor(
    private accountService: AccountService,
    private router: Router,
    public snackBar: MatSnackBar,
    public syncService: SyncService,
    private userIdle: UserIdleService,
  ) {
    this.accountService.currentUser.subscribe(x => this.currentUser = x);
  }

  userLoginForm: FormGroup;

  ngOnInit() {

    if (this.currentUser != null) {
      this.router.navigateByUrl('/dashboard');
    }

    this.userLoginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      keepLoggedIn: new FormControl(false)
    });

  }

  onSubmit(loginFormGroup) {
    if (this.userLoginForm.valid) {
      this.accountService.login(loginFormGroup.username, loginFormGroup.password, loginFormGroup.keepLoggedIn).subscribe(
        res => {
          console.log("Should start sync, login submitted");
          this.syncService.startSync();
          storage.setItem('show-login', false);

          if (!this.isDialog) {
            this.router.navigateByUrl('/client-management');
          }
          else {
            this.closeComponent.emit(true);
          }

        },
        error => {
          this.snackBar.open('Login Attempt Failed', 'ERROR', {
            duration: 2000,
          });
        }
      );
    }


  }



}
