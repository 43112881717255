import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import {LineItem} from '../../line-item-entry/models/line-item.interface';
import {Subject} from 'rxjs';
import {LocationSummary} from '../../configuration-management/models/locations.interface';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseService {

  public searchLineItemsResultObservable = new Subject<LineItem[]>();
  public searchLocationResultObservable = new Subject<LocationSummary[]>();

  public searchItemsNoGroups(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchLineItemsNoGroups/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchLineItems(searchString: string, billingOnly = false) {
    if (!searchString) {
      return;
    }
    const route = `${this.apiUrl}/Search/LineItems`;
    const defaultOptions = this.headers();
    this.http.get<any[]>(route, {headers: defaultOptions.headers, params:
        {searchString, billingOnly: JSON.stringify(billingOnly)}
    }).subscribe(res => {
      this.searchLineItemsResultObservable.next(res);
    });
  }

  public searchLocations(searchString: string) {

    const route = `${this.apiUrl}/Search/Locations`;
    const defaultOptions = this.headers();
    this.http.get<LocationSummary[]>(route, {headers: defaultOptions.headers, params:
        {searchString}
    }).subscribe(res => {
      this.searchLocationResultObservable.next(res);
    });
  }

  public searchFluids(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchFluids/${encodeURIComponent(searchString)}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchVitalGroups(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchVitalGroups/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public searchVitals(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchVitals/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchDailyCare(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchDailyCare/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchLabResults(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchLabResults/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }
  public searchLabs(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchLabs/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public searchServiceCodes(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchServiceCodes/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public searchMedicationsAndSupplies(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchMedicationsAndSupplies/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchGroups(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchGroups/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


}
