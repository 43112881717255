import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {AccountService} from '../../../../configuration-management/services/account.service';
import {LocationService} from '../../../../configuration-management/services/location.service';
import {LineItemInputService} from '../../../../line-item-entry/services/line-item-input.service';
import {
  LineItem,
  LineItemCategory,
  LineItemLocation,
  LineItemSubmission, LineItemTypesWithOverrides, ReminderOverrideBinding, ReminderOverrideView
} from '../../../../line-item-entry/models/line-item.interface';
import {UserAccount, UserOption} from '../../../../configuration-management/models/user.interface';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {UpdateLineItems, UpdateLineItemsComponent} from './update-line-items/update-line-items.dialog';
import {DiscountsService} from 'src/app/configuration-management/services/discounts.service';
import {DiscountSummary} from 'src/app/configuration-management/models/discounts.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GroupInvoiceItemsComponent} from './group-items/group-invoice-items.dialog';
import {SearchService} from '../../../search/search-service';
import {Client} from 'src/app/client-management/models/client.interface';
import {Patient} from 'src/app/patient-management/models/patient.interface';
import {PrintDialogComponent} from './print-dialog/print.dialog';
import {LabsService} from '../../../../configuration-management/services/labs.service';
import {
  EntityWithLineItemsReference,
  EntityWithLineItemsType,
  Invoice
} from '../../../../line-item-entry/models/invoice.interface';
import {InvoiceSelectionComponent} from './invoice-selection/invoice-selection.dialog';
import {FormControl} from '@angular/forms';
import {ConfirmationDialogComponent} from '../../../confirmation-dialog/confirmation.dialog';
import {PatientService} from '../../../../patient-management/services/patient.service';
import {RefillsDialogComponent} from './refills-dialog/refills.dialog';
import {RefillsDialogData} from 'src/app/requests-results/models/request.interface';
import {RequestService} from 'src/app/requests-results/services/request.service';
import {FileUploadModel} from 'src/app/patient-management/patient-record/patient-picture-dialog/patient-picture.dialog';
import {Subscription} from 'rxjs';
import {LocationSummary} from '../../../../configuration-management/models/locations.interface';
import {EstimateService} from '../../../../line-item-entry/services/estimate.service';
import {FeatureFlag} from '../../../../config.model';
import {FeatureFlagService} from '../../../../feature-flag.service';
import {storage} from '../../../local-storage/local-storage.service';
import {LocalStorageNames} from '../../../local-storage/local-storage.enum';
import {
  OverviewReminderOverrideDialogComponent, OverviewReminderOverrideDialogData
} from '../../../overview-reminder-override-dialog/overview-reminder-override.dialog';
import {SearchLineItemDataService} from '../../../search/data-services/search-line-item-data.service';
import {StatementService} from '../../../../list-communications/services/statements.service';
import FileSaver from 'file-saver';
import {
  ControlledSubstanceLogDialogComponent
} from '../../../controlled-substance-log-dialog/controlled-substance-log.dialog';

@Component({
  selector: 'app-line-item-form',
  templateUrl: './line-item-form.component.html',
  styleUrls: ['./line-item-form.component.scss'],
  providers: [SearchLineItemDataService],
})
export class LineItemFormComponent implements OnInit, OnDestroy {
  @Input() lineItemList: LineItem[] = [];
  @Input() selectedClient: Client;
  @Input() invoiceData: EntityWithLineItemsReference;
  @Input() selectedPatient: Patient;
  @Input() closed: boolean;
  @Input() clientInvoices: Invoice[];
  @Input() lineItemDraft: Invoice[];
  @Input() keepRevisions = false;
  @Input() allowConvertingToEstimate = false;
  @Output() reloadInvoice = new EventEmitter<boolean>();
  @Output() updateInvoice = new EventEmitter<Invoice>();
  @ViewChild('table', { static: false }) table: MatTable<any>;
  searchCtrl = null;
  searchCtrl2 = null;
  displayedColumns: string[] = [
    'add',
    'index',
    'select',
    'location',
    'code',
    'date',
    'doctor',
    'asst',
    'quan',
    'price',
    'discount',
    'total',
    'comments',
    'actions',
  ];
  dataSource = new MatTableDataSource([]);
  selection = new SelectionModel<LineItem>(true, []);
  lineItemClipboard: LineItem[];
  hoveredIndex: string;
  autocompleteIndex: number;
  defaultDate = new Date();
  dateOfWork = new Date(
    this.defaultDate.getFullYear(),
    this.defaultDate.getMonth(),
    this.defaultDate.getDate(),
  );
  locations = [];
  selectedLocation: LineItemLocation;
  searchString = new FormControl('');
  lineItems: LineItem[];
  lineItemsToPrint = [];
  doctor: UserOption;
  asstDoctor = new UserOption();
  doctors: UserOption[];
  discounts: DiscountSummary[];
  discount = new DiscountSummary();
  groupPosition = 0;
  submission = new LineItemSubmission();
  submitted = false;
  isCollapsed = false;
  files: Array<FileUploadModel> = [];
  waitingOnFileUpload = false;
  totalOfAllLineItems = 0;
  subscriptions: Subscription;

  areEstimatesEnabled: boolean;

  constructor(
    public accountService: AccountService,
    public locationService: LocationService,
    public lineItemInputService: LineItemInputService,
    public lineItemDataService: SearchLineItemDataService,
    public estimateService: EstimateService,
    public dialog: MatDialog,
    public discountsService: DiscountsService,
    public snackBar: MatSnackBar,
    public searchService: SearchService,
    private cdr: ChangeDetectorRef,
    public labsService: LabsService,
    public statementService: StatementService,
    public patientService: PatientService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public requestService: RequestService,
    public featureFlags: FeatureFlagService,
  ) {
    this.isCollapsed = window.innerWidth < 700;

    iconRegistry.addSvgIcon(
      'request-results-icon-grey',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-lab-grey@1x.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'request-results-icon-blue',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-lab-blue@1x.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'request-results-icon-red',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/icon-lab-red@1x.svg',
      ),
    );
  }

  ngOnInit() {
    this.areEstimatesEnabled = this.featureFlags.getFeatureFlag(
      FeatureFlag.estimatesFlag,
    );

    if (this.invoiceData == null) {
      this.invoiceData = new EntityWithLineItemsReference();
    }

    this.subscriptions = this.searchString.valueChanges.subscribe((value) => {
      var tmp = this.lineItemDataService.input(value);
      return tmp;
    }
    );
    this.subscriptions.add(
      this.lineItemDataService.fetch.dataReceived.subscribe(
        this.setLineItems.bind(this),
      ),
    );
    this.getDoctors();
    this.getDiscounts();

    const estimatePublished = storage.getItem<boolean>(
      LocalStorageNames.EstimatePublished,
      false,
    );
    if (estimatePublished) {
      storage.setItem(LocalStorageNames.EstimatePublished, false);
      this.publishEstimateItems();
    }

    // Read persisted clipboard
    this.lineItemClipboard = storage.getItem<LineItem[]>(
      LocalStorageNames.LineItemClipboard,
      [],
    );
    if (this.lineItemClipboard == null) {
      this.lineItemClipboard = [];
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.lineItemDataService.dispose();
  }

  get validateForm() {
    return (
      this.lineItemList.filter(
        (x) =>
          x.name == null ||
          x.itemId == null ||
          x.name === '' ||
          x.itemId === '',
      ).length > 0
    );
  }

  getTotalPrice(item: LineItem) {
    if (item.itemId === null || item.itemId === undefined || item.itemId === '') {
      return '0';
    }
    const minimumCost = item.minimumCost !== null ? item.minimumCost : 0;
    item.pricePerUnit = Number(item.pricePerUnit.toFixed(2));

    const itemPrice = ((item.pricePerUnit * item.quantity) + minimumCost)

    if (
      item.discountId !== null &&
      item.discountId !== undefined &&
      item.discountId !== ''
    ) {
      item.totalPrice = itemPrice * item.discount.decimalPercentage;
      this.calculateTotal();
      return (itemPrice * item.discount.decimalPercentage).toFixed(2);
    }
    item.totalPrice = itemPrice;
    this.calculateTotal();
    return itemPrice.toFixed(2);
  }

  setLocations(response: LocationSummary[]) {
    this.locations = response;

    if (this.accountService.currentUserValue.defaultLocationId !== null) {
      // tslint:disable-next-line: max-line-length
      this.selectedLocation = JSON.parse(
        JSON.stringify(
          this.locations.filter(
            (x) =>
              x.id === this.accountService.currentUserValue.defaultLocationId,
          ),
        ),
      ) as LineItemLocation;
    } else {
      this.selectedLocation = JSON.parse(
        JSON.stringify(this.locations[0]),
      ) as LineItemLocation;
    }

    if (this.lineItemList.length === 0 && this.invoiceData.id == null) {
      this.addRow(0);

      // Set default location upon arrival
      this.setLocation(
        this.lineItemList[0].location[0].id,
        this.lineItemList[0],
      );
    } else {
      if (this.lineItemList.length > 0) {
        // Set Default Doctor
        this.doctor = JSON.parse(JSON.stringify(this.lineItemList[0].doctor));
        this.doctor.doctorCode = this.lineItemList[0].doctorCode;

        this.asstDoctor = JSON.parse(
          JSON.stringify(this.lineItemList[0].asstDoctor),
        );
        this.asstDoctor.doctorCode = this.lineItemList[0].assistingDoctorCode;
        // Set Default Date
        this.dateOfWork = JSON.parse(
          JSON.stringify(this.lineItemList[0].dateOfWork),
        );
        // Set Default Location
        this.selectedLocation = JSON.parse(
          JSON.stringify(this.lineItemList[0].location),
        );
      }
      this.dataSource.data = this.lineItemList;
    }
  }

  setItemValue(searchString: string) {
    this.searchString.setValue(searchString);
  }

  setLineItems(results: LineItem[]) {
    if (results) {
      this.lineItems = results;
    }
  }

  selectItem(item: LineItem, currentLineItem: LineItem) {
    currentLineItem.totalPrice = item.totalPrice;
    currentLineItem.discountName = item.discountName;
    currentLineItem.code = item.code;
    currentLineItem.nameNoCode = item.nameNoCode;
    currentLineItem.pricePerUnit = item.pricePerUnit;
    currentLineItem.hasAttachment = item.hasAttachment;
    currentLineItem.quantity = item.quantity;
    currentLineItem.name = item.name;
    currentLineItem.itemId = item.id;
    currentLineItem.lineItemCategory = item.lineItemCategory;
    currentLineItem.location = JSON.parse(
      JSON.stringify(this.selectedLocation),
    );
    currentLineItem.approved = false;
    currentLineItem.approvedUserId = '';
    currentLineItem.needsRequest = item.needsRequest;
    currentLineItem.isControlledSubstance = item.isControlledSubstance;
    currentLineItem.causesDeath = item.causesDeath;
    currentLineItem.instructions = item.instructions;
    currentLineItem.minimumCost = item.minimumCost;
    currentLineItem.reminderOverrides = [];

    if (item.lineItemCategory === 'Group') {
      this.groupItemDialog(
        JSON.parse(JSON.stringify(item.groupItems)),
        currentLineItem.position,
      );
    }
    this.showLineItemDialogs(currentLineItem);
  }

  showLineItemDialogs(item: LineItem) {
    if (!this.invoiceData.isEstimate) {
      if (item.causesDeath === true) {
        this.euthenasiaDialog();
      }
      if (item.isControlledSubstance === true) {
        this.controlledSubstanceDialog(item);
      }
      if (LineItemTypesWithOverrides.includes(item.lineItemCategory)) {
        this.checkReminderOverride(item);
      }
    }
  }

  checkReminderOverride(item: LineItem) {
    const sub = this.searchService.overviewReminderOverrides(item.itemId, item.lineItemCategory)
      .subscribe(
      res => {
        for (const reminder of res) {
          this.openOverrideDialog(item, reminder);
        }
      }
    );
    this.subscriptions.add(sub);
  }

  openOverrideDialog(item: LineItem, reminder: ReminderOverrideView) {
    const dialogRef =
      this.dialog.open<
        OverviewReminderOverrideDialogComponent,
        OverviewReminderOverrideDialogData,
        ReminderOverrideBinding>
      (OverviewReminderOverrideDialogComponent, {
        width: '450px',
        data: {patient: this.selectedPatient, reminder}
      });
    const sub = dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (item.reminderOverrides == null) {
          item.reminderOverrides = [];
        }
        item.reminderOverrides.push(response);
      }
    });
    this.subscriptions.add(sub);
  }

  downloadTemplate(lineItem: LineItem) {
    this.labsService
      .getPatientLabPDF(
        lineItem.itemId,
        this.selectedPatient.id,
        this.doctor.id,
      )
      .subscribe((blob) => {
        FileSaver.saveAs(
          blob,
          this.selectedPatient.name + ' ' + lineItem.name + '.pdf',
        );
      });
  }

  getFilesAndUpload(item: LineItem) {
    const fileUpload = document.getElementById(
      'fileUpload',
    ) as HTMLInputElement;
    fileUpload.onchange = () => {
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({
          data: file,
          state: 'in',
          inProgress: false,
          progress: 0,
          canRetry: false,
          canCancel: true,
        });
      }
      this.uploadFiles(item);
    };
    fileUpload.click();
  }

  uploadFiles(item: LineItem) {
    this.waitingOnFileUpload = true;
    const fileUpload = document.getElementById(
      'fileUpload',
    ) as HTMLInputElement;
    fileUpload.value = '';

    if (this.files.length >= 1) {
      const files = this.files.map((element) => element.data);

      this.requestService
        .uploadLabRequestFileTemporarilySavedOnQuickEntry(files)
        .subscribe(
          (result) => {
            this.files = [];
            item.labRequestTemporaryS3FileId = result.fileId;
            this.snackBar.open(
              'Patient file successfully uploaded',
              'Success',
              {
                duration: 2000,
              },
            );
            this.waitingOnFileUpload = false;
          },
          (error) => {
            this.snackBar.open('Patient file was not uploaded', 'Error', {
              duration: 2000,
            });
            this.files = [];
            this.waitingOnFileUpload = false;
          },
        );
    }
  }

  euthenasiaDialog() {
    if (
      this.selectedPatient == null ||
      this.selectedPatient.patientStatus === 'Deceased'
    ) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        header: 'WARNING: Euthanasia',
        body: `You are about to change ${this.selectedPatient.name}'s status to deceased.`,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res != null) {
        this.patientService.euthenize(this.selectedPatient.id).subscribe(
          () => {
            this.selectedPatient.patientStatus = 'Deceased';
            this.snackBar.open('Patient status was updated.', 'Success', {
              duration: 2000,
            });
          },
          (error) => {
            this.snackBar.open('Patient could not be updated.', 'Error', {
              duration: 2000,
            });
          },
        );
      }
    });
  }

  controlledSubstanceDialog(lineItem: LineItem) {
    const dialogRef = this.dialog.open(ControlledSubstanceLogDialogComponent, {
      width: '450px',
      data: {
        lineItem,
        patientId: this.selectedPatient.id,
        patient: this.selectedPatient,
        client: this.selectedClient,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {});
  }

  groupItemDialog(groupItems: LineItem[], position: number) {
    const dialogRef = this.dialog.open(GroupInvoiceItemsComponent, {
      width: '450px',
      data: groupItems,
    });
    this.groupPosition = position;

    dialogRef.afterClosed().subscribe((lineItems) => {
      if (lineItems != null) {
        lineItems.forEach((lineItem) => {
          this.addLineItemRow(this.groupPosition, lineItem);

          this.showLineItemDialogs(this.lineItemList[this.groupPosition]);

          this.groupPosition = position + 1;
        });
        this.groupPosition = 0;
      }
    });
  }

  publishEstimateItems() {
    this.lineItemList.forEach((item) => {
      if (item.causesDeath === true) {
        this.euthenasiaDialog();
      }

      if (item.isControlledSubstance === true) {
        this.controlledSubstanceDialog(item);
      }
      if (LineItemTypesWithOverrides.includes(item.lineItemCategory)) {
        this.checkReminderOverride(item);
      }
    });
  }

  addLineItemRow(index: number, newLineItem: LineItem) {
    this.canEditInvoice();
    this.lineItemList
      .filter((x) => x.position > index)
      .sort((x) => x.position)
      .forEach((lineItem) => {
        lineItem.position = lineItem.position + 1;
      });
    const item = newLineItem;
    item.approved = false;
    item.position = index + 1;
    item.dateOfWork = this.dateOfWork;
    item.itemId = newLineItem.id;
    item.doctor = JSON.parse(JSON.stringify(this.doctor)) as UserAccount;
    item.location = JSON.parse(
      JSON.stringify(this.selectedLocation),
    ) as LineItemLocation;
    item.asstDoctor = JSON.parse(
      JSON.stringify(this.asstDoctor),
    ) as UserAccount;
    item.doctorCode = this.doctor.doctorCode;
    item.assistingDoctorCode = this.asstDoctor.doctorCode;
    item.discount = JSON.parse(
      JSON.stringify(this.discount),
    ) as DiscountSummary;
    if (item.discount != null) {
      item.discountId = item.discount.id;
    }
    item.id = null;

    this.lineItemList.push(item);

    if (this.lineItemList.length > 1) {
      this.lineItemList = this.lineItemList
        .sort((a, b) => a.position - b.position)
        .map((x) => x);
    }
    this.dataSource.data = this.lineItemList;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  handleMouseOver(row) {
    this.hoveredIndex = row.position;
  }

  canEditInvoice() {
    if (this.closed === true && !this.accountService.canFinalize) {
      this.snackBar.open('User is not allowed to edit invoice.', 'Error', {
        duration: 2000,
      });
      throw new Error('User is not allowed to edit invoice');
    }
  }

  approveSelected() {
    this.canEditInvoice();
    this.selection.selected.forEach((lineItem) => {
      this.setApproved(lineItem);
    });
  }

  addRow(index) {
    this.canEditInvoice();
    this.lineItemList
      .filter((x) => x.position > index)
      .sort((x) => x.position)
      .forEach((lineItem) => {
        lineItem.position = lineItem.position + 1;
      });

    const item = new LineItem();
    item.position = index + 1;
    item.name = '';
    item.isPrinted = false;

    if (index !== 0) {
      const aboveItem = JSON.parse(
        JSON.stringify(this.lineItemList[index - 1]),
      ) as LineItem;

      item.dateOfWork = aboveItem.dateOfWork;
      this.dateOfWork = aboveItem.dateOfWork;
      item.doctorCode = aboveItem.doctorCode;
      item.doctor = JSON.parse(JSON.stringify(aboveItem.doctor)) as UserAccount;
      item.assistingDoctorCode = aboveItem.assistingDoctorCode;
      item.asstDoctor = JSON.parse(
        JSON.stringify(aboveItem.asstDoctor),
      ) as UserAccount;
      item.location = JSON.parse(
        JSON.stringify(aboveItem.location),
      ) as LineItemLocation;
      item.discount = JSON.parse(
        JSON.stringify(aboveItem.discount),
      ) as DiscountSummary;
      if (aboveItem.discount != null) {
        item.discountId = aboveItem.discountId;
      }
    } else {
      item.dateOfWork = this.dateOfWork;
      item.doctorCode = this.doctor.doctorCode;
      item.doctor = JSON.parse(JSON.stringify(this.doctor)) as UserAccount;
      item.assistingDoctorCode = this.asstDoctor.doctorCode;
      item.location = JSON.parse(
        JSON.stringify(this.selectedLocation),
      ) as LineItemLocation;
      item.asstDoctor = JSON.parse(
        JSON.stringify(this.asstDoctor),
      ) as UserAccount;
      item.discount = JSON.parse(
        JSON.stringify(this.discount),
      ) as DiscountSummary;
      if (item.discount != null) {
        item.discountId = item.discount.id;
      }
    }

    this.lineItemList.push(item);

    if (this.lineItemList.length > 1) {
      this.lineItemList = this.lineItemList
        .sort((a, b) => a.position - b.position)
        .map((x) => x);
    }

    this.dataSource.data = this.lineItemList;
  }

  calculateTotal() {
    let total = 0;
    this.lineItemList.forEach((x) => {
      total += x.totalPrice;
    });

    this.totalOfAllLineItems = total;
  }

  setApproved(item: LineItem) {
    this.canEditInvoice();
    item.approved = true;
    item.approvedUserId = this.doctor.id;
  }

  clearApproved(item: LineItem) {
    this.canEditInvoice();
    item.approved = false;
    item.approvedUserId = '';
  }

  changeDefaultLocation(location: LineItemLocation) {
    this.canEditInvoice();
    this.selectedLocation = location;
  }

  changeDefaultDate(date: Date) {
    this.canEditInvoice();
    this.dateOfWork = date;
  }

  changeDefaultDoctor(doctor: UserOption) {
    this.canEditInvoice();
    this.doctor = doctor;
  }

  changeDefaultAsstDoctor(doctor: UserOption) {
    this.canEditInvoice();
    this.asstDoctor = doctor;
  }

  changeDefaultDiscount(discount: DiscountSummary) {
    this.canEditInvoice();
    this.discount = JSON.parse(JSON.stringify(discount));
  }

  setAsstDoctor(lineItem: LineItem, userName: string) {
    this.canEditInvoice();
    const doctor = this.doctors.find((x) => x.name === userName);
    lineItem.asstDoctor = JSON.parse(JSON.stringify(doctor));
    lineItem.assistingDoctorCode = doctor.doctorCode;
    this.changeDefaultAsstDoctor(doctor);
  }

  setLocation(locationId: string, lineItem: LineItem) {
    this.canEditInvoice();
    const location = this.locations.find((x) => x.id === locationId);
    this.selectedLocation = JSON.parse(JSON.stringify(location));
    lineItem.location = this.selectedLocation;
  }

  setDoctor(lineItem: LineItem, userName: string) {
    this.canEditInvoice();
    const doctor = this.doctors.find((x) => x.name === userName);
    lineItem.doctor = JSON.parse(JSON.stringify(doctor));
    lineItem.doctorCode = doctor.doctorCode;
    this.changeDefaultDoctor(doctor);
  }

  setDiscount(lineItem: LineItem, discountId: string) {
    this.canEditInvoice();
    this.table.renderRows();
    if (discountId === '' || discountId === undefined || discountId === null) {
      lineItem.discount = JSON.parse(JSON.stringify(new DiscountSummary()));
      lineItem.discountId = '';
      lineItem.discountName = '';
      lineItem.totalPrice = lineItem.quantity * lineItem.pricePerUnit + lineItem.minimumCost;
      this.discount = new DiscountSummary();
    } else {
      const discount = JSON.parse(
        JSON.stringify(this.discounts.find((x) => x.id === discountId)),
      );
      lineItem.discount = discount;
      lineItem.discountId = discount.id;
      lineItem.discountName = discount.name;
      lineItem.totalPrice =
        (lineItem.quantity * lineItem.pricePerUnit + lineItem.minimumCost) * discount.decimalPercentage;
      this.changeDefaultDiscount(discount);
    }
    this.table.renderRows();
  }

  updateSelected(): void {
    this.canEditInvoice();
    const dialogRef = this.dialog.open(UpdateLineItemsComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      const lineItemChanges = result as UpdateLineItems;
      this.selection.selected.forEach((lineItem) => {
        const listItem = this.lineItemList.find((x) => x === lineItem);

        // A null or undefined ID represents a field that wasn't changed.
        // An empty ID represents a field that should be reset.

        // The new value has to be copied, otherwise changes to individual
        // rows would affect every other row.

        // Changes to the location
        if (lineItemChanges.location.id != null) {
          if (lineItemChanges.location.id === '') {
            throw Error(
              'Location cannot be unset. This may be a bug with update-line-items',
            );
          }
          listItem.location = JSON.parse(
            JSON.stringify(lineItemChanges.location),
          );
          this.changeDefaultLocation(listItem.location);
        }

        // Changes to the date
        if (lineItemChanges.dateOfWork != null) {
          listItem.dateOfWork = lineItemChanges.dateOfWork;
          this.changeDefaultDate(lineItemChanges.dateOfWork);
        }

        // Changes to the main doctor
        if (lineItemChanges.doctor.id != null) {
          if (lineItemChanges.doctor.id === '') {
            throw Error(
              'Doctor cannot be unset. This may be a bug with update-line-items',
            );
          }
          listItem.doctor = JSON.parse(JSON.stringify(lineItemChanges.doctor));
          listItem.doctorCode = lineItemChanges.doctor.doctorCode;
          this.changeDefaultDoctor(listItem.doctor);
        }

        // Changes to the assisting doctors
        if (lineItemChanges.asstDoctor.id != null) {
          if (lineItemChanges.asstDoctor.id !== '') {
            listItem.asstDoctor = JSON.parse(
              JSON.stringify(lineItemChanges.asstDoctor),
            );
            listItem.assistingDoctorCode =
              lineItemChanges.asstDoctor.doctorCode;
          } else {
            listItem.asstDoctor = new UserAccount();
            listItem.assistingDoctorCode = null;
          }
          this.changeDefaultAsstDoctor(listItem.asstDoctor);
        }

        // Applied Discounts
        if (lineItemChanges.discount.id != null) {
          listItem.totalPrice = listItem.quantity * listItem.pricePerUnit + listItem.minimumCost;

          if (lineItemChanges.discount.id !== '') {
            listItem.discount = JSON.parse(
              JSON.stringify(lineItemChanges.discount),
            );
            listItem.discountId = lineItemChanges.discount.id;
            listItem.discountName = lineItemChanges.discount.name;
            listItem.totalPrice *= lineItemChanges.discount.decimalPercentage;
          } else {
            listItem.discount = new DiscountSummary();
            listItem.discountId = null;
            listItem.discountName = null;
          }

          this.changeDefaultDiscount(listItem.discount);
        }
      });

      this.dataSource.data = this.lineItemList;
    });
  }

  getDiscounts() {
    this.subscriptions.add(
      this.discountsService.index().subscribe((res) => {
        this.discounts = res;
      }),
    );
  }

  getDoctors() {
    this.subscriptions.add(
      this.accountService.getDoctorOptions().subscribe((res) => {
        this.doctors = res;
        const currentDoctor = this.accountService
          .currentUserValue as unknown as UserAccount;
        if (this.doctors.filter((x) => x.id === currentDoctor.id).length > 0) {
          this.doctor = JSON.parse(
            JSON.stringify(
              this.doctors.filter((x) => x.id === currentDoctor.id)[0],
            ),
          );
        } else {
          this.doctor = JSON.parse(
            JSON.stringify(this.doctors.filter((x) => x.userName === 'NP')[0]),
          ) as UserAccount;
        }

        this.subscriptions.add(
          this.locationService
            .getInventoryLocations()
            .subscribe(this.setLocations.bind(this)),
        );
      }),
    );
  }

  printDialog(selectedItems: LineItem[]) {
    const dialogRef = this.dialog.open(PrintDialogComponent, {
      disableClose: true,
      height: 'auto',
      width: 'auto',
      data: {
        selectedClient: this.selectedClient,
        selectedPatient: this.selectedPatient,
        selectedItems,
        isWhiteboard: false,
      },
    });

    const sub = dialogRef.componentInstance.lineItemsToPrint.subscribe(
      (data: any) => {
        this.printLabel(data);
      },
    );

    dialogRef.afterClosed().subscribe((result) => {});
  }

  printLabel(selectedItems: LineItem[]) {
    for (const item of selectedItems) {
      item.isPrinted = true;
    }

    this.lineItemsToPrint = selectedItems;
    this.cdr.detectChanges();
    const printContent = document.getElementById('print');
    const WindowPrt = window.open(
      '',
      '',
      'left=0,top=0,toolbar=0,scrollbars=0,status=0',
    );
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.onafterprint = () => WindowPrt.close();
    WindowPrt.print();
  }

  public discard() {
    this.lineItemInputService.discardLineItemDraft().subscribe((res) => {
      this.selection.clear();
      this.lineItemList = [];
      this.addRow(0);
      this.submitted = false;
      this.snackBar.open('Draft was removed', 'Success', {
        duration: 2000,
      });
    });
  }

  removeItem(lineItem: LineItem) {
    this.canEditInvoice();
    if (
      this.invoiceData.id != null &&
      lineItem.hasOwnProperty('id') &&
      lineItem.id
    ) {
      this.removeInvoiceItem(lineItem);
      return;
    }

    if (this.lineItemList.length > 1) {
      this.lineItemList = this.lineItemList
        .filter((x) => x !== lineItem)
        .sort((x) => x.position);

      this.lineItemList.forEach((item, index) => {
        item.position = index + 1;
      });
      this.dataSource.data = this.lineItemList;

      setTimeout(() => {}, 2000);
    } else {
      this.snackBar.open('Must have at least one line item', 'Warning', {
        duration: 2000,
      });
    }
  }

  copySelected() {
    this.canEditInvoice();

    this.lineItemClipboard.length = 0;

    for (const lineItem of this.selection.selected) {
      // Need to make a copy so that changes to the source item aren't propagated to this copy
      const copiedLineItem = JSON.parse(JSON.stringify(lineItem)) as LineItem;
      copiedLineItem.lineItemRequest = null;
      if (copiedLineItem.lineItemCategory === 'MedicationsAndSupplies') {
        copiedLineItem.needsRequest = false;
      }
      this.lineItemClipboard.push(copiedLineItem);
    }

    // Persist clipboard
    storage.setItem(
      LocalStorageNames.LineItemClipboard,
      this.lineItemClipboard,
    );

    this.selection.clear();
  }

  pasteSelected(listPasteIndex: number) {
    const aboveDateOfWork =
      listPasteIndex > 0
        ? this.lineItemList[listPasteIndex - 1].dateOfWork
        : new Date(Date.now());

    // Insert line items from clipboard
    for (let i = 0; i < this.lineItemClipboard.length; i++) {
      // Need to make a copy so that items can be pasted more than once
      const newLineItem = JSON.parse(JSON.stringify(this.lineItemClipboard[i]));

      newLineItem.dateOfWork = aboveDateOfWork;
      newLineItem.id = null;

      this.lineItemList.splice(listPasteIndex + i, 0, newLineItem);
      this.showLineItemDialogs(newLineItem);
    }

    // Update positions
    for (let i = listPasteIndex; i < this.lineItemList.length; i++) {
      const lineItem = this.lineItemList[i];
      lineItem.position = i + 1;
    }

    this.dataSource.data = this.lineItemList;
  }

  deleteSelected() {
    this.canEditInvoice();
    const selectionIds = this.selection.selected
      .filter((x) => x.id != null)
      .map(({ id }) => id);

    if (this.invoiceData.id !== undefined && this.invoiceData.id !== null) {
      if (this.invoiceData.isEstimate) {
        this.estimateService.deleteItems(selectionIds).subscribe(
          (x) => this.deleteSuccess(selectionIds),
          (error) =>
            this.snackBar.open('Line items could not be removed', 'Error', {
              duration: 2000,
            }),
        );
      } else {
        this.lineItemInputService
          .removeInvoiceItems(this.invoiceData.id, selectionIds)
          .subscribe(
            (x) => this.deleteSuccess(selectionIds),
            (error) =>
              this.snackBar.open('Line items could not be removed', 'Error', {
                duration: 2000,
              }),
          );
      }
    } else {
      this.selection.selected.forEach((lineItem) => {
        this.removeItem(lineItem);
      });

      this.selection.clear();
    }
  }

  deleteSuccess(selectionIds: string[]) {
    this.lineItemList = this.lineItemList
      .filter((y) => !selectionIds.includes(y.id))
      .sort((y) => y.position);

    this.lineItemList.forEach((item, index) => {
      item.position = index + 1;
    });

    this.selection.clear();

    this.dataSource.data = this.lineItemList;
    this.reloadInvoice.emit(true);
    this.snackBar.open('Line Items have been removed', 'Success', {
      duration: 2000,
    });
  }

  // Items that Invoice Id
  removeInvoiceItem(lineItem: LineItem) {
    this.canEditInvoice();

    const request = this.invoiceData.isEstimate
      ? this.estimateService.deleteLineItem(lineItem.id)
      : this.lineItemInputService.removeInvoiceItem(
          this.invoiceData.id,
          lineItem.id,
          this.keepRevisions,
        );

    request.subscribe(
      (x) => {
        this.lineItemList = this.lineItemList
          .filter((y) => y.id !== lineItem.id)
          .sort((y) => y.position);
        this.lineItemList.forEach((item, index) => {
          item.position = index + 1;
        });

        this.dataSource.data = this.lineItemList;

        this.snackBar.open('Line Item has been removed', 'Success', {
          duration: 2000,
        });
      },
      (error) => {
        this.snackBar.open('Line item could not be removed', 'Error', {
          duration: 2000,
        });
      },
    );
  }

  hideInvoiceItem(lineItem: LineItem) {
    this.canEditInvoice();
    this.lineItemInputService
      .hideInvoiceItem(this.invoiceData.id, lineItem.id)
      .subscribe(
        (x) => {
          lineItem.hideFromInvoice = true;
          this.reloadInvoice.emit(true);
          this.snackBar.open('Line Item has been hidden', 'Success', {
            duration: 2000,
          });
        },
        (error) => {
          this.snackBar.open('Line item could not be hidden', 'Error', {
            duration: 2000,
          });
        },
      );
  }

  updateClosedInvoice() {
    // send back id and new line item list
    this.statementService
      .updateClosedInvoice(this.invoiceData.id, this.lineItemList)
      .subscribe((x) => {});
  }

  updateSubmissionWithSelected() {
    this.submission.clientId = this.selectedClient.id;
    this.submission.patientId = this.selectedPatient.id;
    this.submission.selectedClient = this.selectedClient;
    this.submission.selectedPatient = this.selectedPatient;
    this.submission.lineItems = this.lineItemList;
  }

  // New functions
  submitLineItems(invoice: Invoice) {
    if (this.invoiceData.isEstimate) {
      this.updateSubmissionWithSelected();
      if (this.invoiceData.id) {
        this.estimateService
          .update(this.submission, this.invoiceData.id)
          .subscribe((res) => {
            this.lineItemList = res.lineItems;

            this.lineItemList.forEach((item, index) => {
              item.position = index + 1;
            });

            this.dataSource.data = this.lineItemList;

            this.snackBar.open('Estimate has been updated.', 'Success', {
              duration: 2000,
            });
          });
      } else {
        this.estimateService.create(this.submission).subscribe((res) => {
          // Clear form
          this.selection.clear();
          this.lineItemList = [];
          this.addRow(0);
          this.submitted = false;

          this.snackBar.open('New estimate successfully created.', 'Success', {
            duration: 2000,
          });
        });
      }
    } else if (invoice === null || invoice.id === undefined) {
      this.updateSubmissionWithSelected();
      if (invoice !== null && invoice.id === undefined) {
        this.submission.invoiceStatus = invoice.invoiceStatus;
      }

      if (this.invoiceData.id) {
        console.log(this.submission);
        this.lineItemInputService
          .updateLineItems(
            this.submission,
            this.invoiceData.id,
            this.keepRevisions,
          )
          .subscribe((res) => {
            this.lineItemList = res.lineItems;

            this.lineItemList.forEach((item, index) => {
              item.position = index + 1;
            });

            this.dataSource.data = this.lineItemList;

            this.updateInvoice.emit(res);
            this.snackBar.open('Invoice has been updated.', 'Success', {
              duration: 2000,
            });
          });
      } else {
        this.lineItemInputService.submitLineItems(this.submission).subscribe(
          (response) => {
            this.snackBar.open('Invoice submitted.', 'Success', {
              duration: 2000,
            });

            // Discard
            this.selection.clear();
            this.lineItemList = [];
            this.addRow(0);
            this.submitted = false;
          },
          (error) => {
            this.snackBar.open('Invoice could not be submitted.', 'Error', {
              duration: 2000,
            });
          },
        );
      }
    } else {
      this.lineItemInputService
        .addLineItems(
          this.selectedClient.id,
          this.selectedPatient.id,
          this.lineItemList,
          invoice.id,
          invoice.invoiceStatus,
        )
        .subscribe((response) => {
          this.snackBar.open('Invoice Submitted', 'Success', {
            duration: 2000,
          });

          // Discard
          this.selection.clear();
          this.lineItemList = [];
          this.addRow(0);
          this.submitted = false;
        });
    }
  }

  /// Switches form to estimate mode
  convertToEstimate(): void {
    this.invoiceData.type = EntityWithLineItemsType.Estimate;
  }

  // If submitted then update instead
  updateLineItems() {
    this.canEditInvoice();
    if (!this.invoiceData.isEstimate && this.invoiceData.id == null) {
      const dialogRef = this.dialog.open(InvoiceSelectionComponent, {
        width: '600px',
        data: this.clientInvoices,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          return;
        }
        this.submitLineItems(result);
      });
    } else {
      this.submitLineItems(null);
    }
  }

  get unapprovedItems() {
    if (this.lineItemList) {
      return this.lineItemList.filter(
        (x) => !x.approved && x.name !== '' && x.itemId !== '',
      ).length as number;
    } else {
      return 0 as number;
    }
  }

  printLabels(lineItems: LineItem[]) {
    window.print();
  }

  showRefillsDialog(lineItem) {
    if (this.invoiceData.isEstimate) {
      return;
    }

    const previousRefillData = lineItem.lineItemRequest;

    const dialogData = new RefillsDialogData();
    dialogData.IsEditable =
      lineItem.lineItemRequest === null ||
      lineItem.lineItemRequest === undefined ||
      (lineItem.lineItemRequest.refillStatus !== 'DroppedOff' &&
        lineItem.lineItemRequest.refillStatus !== 'PickedUp');
    dialogData.LineItem = lineItem;

    const dialogRef = this.dialog.open(RefillsDialogComponent, {
      disableClose: true,
      height: 'auto',
      width: 'auto',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((data) => {
      const lineItemInput = data as LineItem;

      // If finalizing request / setting status to picked up, confirm with user
      if (
        lineItemInput.lineItemRequest.refillStatus === 'PickedUp' ||
        lineItemInput.lineItemRequest.refillStatus === 'DroppedOff'
      ) {
        const confDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          height: 'auto',
          width: 'auto',
          data: {
            header: 'Approve Request',
            body: `You are about to complete a request for ${this.selectedClient.name}`,
          },
        });

        confDialogRef.afterClosed().subscribe((result) => {
          if (result && lineItemInput != null) {
            lineItem.lineItemRequest.refillDate =
              lineItemInput.lineItemRequest.refillDate;
            lineItem.lineItemRequest.refillStatus =
              lineItemInput.lineItemRequest.refillStatus;
            lineItem.lineItemRequest.refillStatusDate =
              lineItemInput.lineItemRequest.refillStatusDate;
            lineItem.lineItemRequest.refillStatusUserId =
              lineItemInput.lineItemRequest.refillStatusUserId;
          } else {
            lineItem.lineItemRequest = previousRefillData;
          }
        });
      }

      if (lineItemInput !== null && lineItemInput !== undefined) {
        const existingLineItem = this.lineItemList.find(
          (x) => x.position === lineItemInput.position,
        );
        existingLineItem.lineItemRequest = lineItemInput.lineItemRequest;
        if (
          existingLineItem.lineItemRequest.refillStatusUserId !== null &&
          existingLineItem.lineItemRequest.refillStatusUserId !== undefined
        ) {
          existingLineItem.needsRequest = true;
        }
      }
      this.cdr.detectChanges();
    });
  }

  setAutoCompleteIndex(i: number) {
    this.autocompleteIndex = i;
  }

  getInvoiceOrEstimateTitle(): string {
    return this.invoiceData.isEstimate ? 'ESTIMATE' : 'INVOICE';
  }
}
