import {Component, Inject, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Patient } from '../../patient-management/models/patient.interface';
import { FormGroup, FormControl } from '@angular/forms';
import {ReminderOverrideBinding, ReminderOverrideView} from '../../line-item-entry/models/line-item.interface';
import {Subscription} from 'rxjs';


export interface OverviewReminderOverrideDialogData {
  reminder: ReminderOverrideView;
  patient: Patient;
}

@Component({
  selector: 'app-overview-reminder-override-dialog',
  templateUrl: './overview-reminder-override.dialog.html',
  styleUrls: ['./overview-reminder-override.dialog.scss']
})
export class OverviewReminderOverrideDialogComponent implements OnDestroy {
  overrideForm = new FormGroup({
    occurrenceDays: new FormControl(0),
    occurrenceWeeks: new FormControl(0),
    occurrenceMonths: new FormControl(0),
  });

  subscriptions: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<OverviewReminderOverrideDialogComponent, ReminderOverrideBinding>,
    @Inject(MAT_DIALOG_DATA) public data: OverviewReminderOverrideDialogData,
    public snackBar: MatSnackBar,
  ) { }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  submit() {
    const form = this.overrideForm.value as ReminderOverrideBinding;
    if (form.occurrenceDays || form.occurrenceWeeks || form.occurrenceMonths) {
      form.overviewReminderId = this.data.reminder.overviewReminder.id;
    }
    this.dialogRef.close(form);
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
