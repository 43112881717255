import {
  CalendarEventClient,
  CalendarEventUser,
  GoogleLocation
} from '../../schedule/models/schedule.models';
import {Client} from "../../client-management/models/client.interface";

export class AvailableAppointment {
  startTimeHours: number;
  endTimeHours: number;
  date: Date;
  appointmentStartTime: number;
  appointmentEndTime: number;
  doctorId: string;
  doctorName: string;
  fromAddress: string;
  distanceMiles: number;
}

export class ExistingAppointment {
  id: string;
  date: Date;
  startTimeHoursEnum: number;
  endTimeHoursEnum: number;
  availableTimeBefore: number;
  availableTimeAfter: number;
  googleLocation: GoogleLocation;
  calendarEventType: string;
  clients: CalendarEventClient[];
  doctors: CalendarEventUser[];
  distance: number;
}

export class ExistingAppointmentParams {
  createBeforeAfter: CreateBeforeAfter;
  location: GoogleLocation;
}

export enum CreateBeforeAfter {
  before = 'before',
  after = 'after'
}
