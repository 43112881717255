import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Client } from 'src/app/client-management/models/client.interface';
import { ClientService } from 'src/app/client-management/services/client.service';
import { debounceTime, tap, switchMap, finalize, startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-search-clients',
  templateUrl: './search-clients.component.html',
  styleUrls: ['./search-clients.component.scss']
})
export class SearchClientsComponent implements OnInit, OnChanges {

  @Input() client: Client;
  @Output() selectedClient = new EventEmitter<Client>();
  @Input() disabled: boolean;
  clientSearch = new FormControl();
  clientResults: Client[] = [];
  filteredOptions: Observable<Client[]>;
  constructor(
    public clientService: ClientService
  ) { }

  ngOnInit() {

    this.getClients();

    if (this.client) {
      this.clientSearch = new FormControl(this.client.name);
    } else {
      this.client = new Client();
    }

    if (this.disabled) {
      this.clientSearch.disable();
    }


  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.client.id !== '') {
      this.clientSearch.setValue(this.client.name);
      if (this.disabled) {
        this.clientSearch.disable();
      }
    } else {
      this.clientSearch.setValue('');
    }
  }
  getClients() {
    this.clientService.searchClients().then(
      response => {
        this.clientResults = response.filter(x => x.clientStatus !== 'Deleted');

        this.filteredOptions = this.clientSearch.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    );
  }




  selectClient(client: Client) {

    this.client = client as Client;


    this.selectedClient.emit(this.client);

    this.clientSearch.setValue(this.client.name);
  }

  private _filter(value: string): Client[] {
    let filterValue = '';
    if ( typeof value === 'string') {
      filterValue = value.toLowerCase();
    }
    return this.clientResults.filter(client => client.name.toLowerCase().includes(filterValue)).slice(0, 30);
  }

}
