import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { CalendarEvent, GoogleLocation, Calendar } from '../models/schedule.models';
import { GoogleDistance } from '../models/google-location.models';
import * as moment from 'moment-timezone';
import {AvailableAppointment, ExistingAppointment} from "../../shared/appointment-search/appointment-search.model";

@Injectable({
  providedIn: 'root',
})
export class CalendarService extends BaseService {

  public findScheduleConflicts(calendarEvent: CalendarEvent): Observable<string[]> {

    const route = `${this.apiUrl}/Calendar/FindScheduleConflicts`;
    const body = JSON.stringify(calendarEvent);
    return this.http.post<string[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public postEvent(calendarEvent: CalendarEvent): Observable<any> {

    calendarEvent.date = new Date(calendarEvent.date);
    const m = moment.utc(calendarEvent.date, 'DD-MM-YYYY h:mm:ss A'); // parse input as UTC
    const tz = 'America/Los_Angeles';
    m.set({hour: 7, minute: 0, second: 0, millisecond: 0});
    const newDate = m.clone().tz(tz).format();


    calendarEvent.date = newDate;

    const route = `${this.apiUrl}/Calendar`;
    const body = JSON.stringify(calendarEvent);
    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public cancelEvent(id: string): Observable<any> {

    const route = `${this.apiUrl}/Calendar/${id}/Cancel`;
    return this.http.post<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public checkIn(id: string): Observable<any> {

    const route = `${this.apiUrl}/Calendar/${id}/CheckIn`;
    return this.http.post<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public checkOut(id: string): Observable<any> {

    const route = `${this.apiUrl}/Calendar/${id}/CheckOut`;
    return this.http.post<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public restoreEvent(id: string): Observable<any> {

    const route = `${this.apiUrl}/Calendar/${id}/Restore`;
    return this.http.post<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getEvents(startDate: any, endDate: any, cancelled: boolean): Observable<Calendar> {

    startDate = new Date(startDate.toString());
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
    const route = `${this.apiUrl}/Calendar/GetCalendar`;
    const body = JSON.stringify({ startDate, endDate, cancelled });
    return this.http.post<Calendar>(route, body, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public getWeekEvents(startDate: any, endDate: any, cancelled: boolean, userId: string): Observable<Calendar> {

    startDate = new Date(startDate.toString());
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
    const route = `${this.apiUrl}/Calendar/GetCalendar/${userId}`;
    const body = JSON.stringify({ startDate, endDate, cancelled });
    return this.http.post<Calendar>(route, body, this.headers()).pipe(map(response => {

      return response;
    }));
  }


  // Appointments
  public patientAppointments( patientId: string, startDate: Date, endDate: Date): Observable<CalendarEvent[]> {

    if (startDate === undefined) {
      startDate = new Date();
      endDate = new Date();
    }
    const route = `${this.apiUrl}/Calendar/PatientAppointments/${patientId}`;
    const body = JSON.stringify({ startDate, endDate });
    return this.http.post<CalendarEvent[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public clientAppointments( clientId: string, startDate: any, endDate: any): Observable<CalendarEvent[]> {

    if (startDate === undefined) {
      startDate = new Date();
      endDate = new Date();
    }

    const route = `${this.apiUrl}/Calendar/ClientAppointments/${clientId}`;
    const body = JSON.stringify({ startDate, endDate });
    return this.http.post<CalendarEvent[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public userAppointments( userId: string, startDate: any, endDate: any): Observable<CalendarEvent[]> {

    if (startDate === undefined) {
      startDate = new Date();
      endDate = new Date();
    }

    const route = `${this.apiUrl}/Calendar/UserAppointments/${userId}`;
    const body = JSON.stringify({ startDate, endDate });
    return this.http.post<CalendarEvent[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public dashboardAppointments( userId: string, startDate: any, endDate: any): Observable<CalendarEvent[]> {

    if (startDate === undefined) {
      startDate = new Date();
      endDate = new Date();
    }

    const route = `${this.apiUrl}/Calendar/DashboardAppointments/${userId}`;
    const body = JSON.stringify({ startDate, endDate });
    return this.http.post<CalendarEvent[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
  public sendConfirmation( id: string, sendToClients: boolean, sendToDoctors: boolean): Observable<any> {


    const route = `${this.apiUrl}/Calendar/${id}/SendConfirmation`;
    const body = JSON.stringify({ sendToClients, sendToDoctors });
    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  calculateTravelTime(startPlaceId: string, endPlaceId: string): Observable<GoogleDistance> {
    const route = `${this.apiUrl}/Calendar/TravelTime/Start/${startPlaceId}/End/${endPlaceId}`;
    return this.http.get<GoogleDistance>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  searchAppointmentSlots(startDate?: Date,
                         endDate?: Date,
                         startTime?: number,
                         endTime?: number,
                         doctorIds?: string[],
                         daysOfWeek?: string[],
                         location?: GoogleLocation,
                         distanceMiles?: number,
                         duration?: number,
                         travelTime?: number) {
    console.log('Searching...');
    const route = `${this.apiUrl}/Calendar/Search`;
    const body = {
      startDate,
      endDate,
      startTime,
      endTime,
      daysOfWeek,
      doctorIds,
      location,
      distanceMiles,
      duration,
      travelTime
    };
    return this.http.post<ExistingAppointment[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
