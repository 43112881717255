import {LocalStorageNames} from './local-storage.enum';

export const storage = {
  getItem<T>(key: string, fallbackValue: T): T {
    try {
      if (typeof fallbackValue === 'string' || typeof fallbackValue === 'boolean') {
          return JSON.parse(localStorage.getItem(key)) as T;
      }
      const returnValue = JSON.parse(localStorage.getItem(key)) as T;
      return returnValue || fallbackValue; // null coalesce
    } catch (e) {
      console.log('warning: failed to retrieve fallback item', key);
      return fallbackValue;
    }
  },
  setItem<T>(key: string, value: T) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (e) {
      // TODO
      localStorage.removeItem(LocalStorageNames.DashboardCalendar);
      try {
        localStorage.setItem(key, JSON.stringify(value));
        return true;
      } catch (e) {
        console.error(`Could not save data for: ${key}`);
        return false;
      }
    }
  },
  removeItem(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.log(`Could not remove key: ${key}\n ERR: ${e}`);
    }
  },
};
