export class EquipmentSummary {
  id: string;
  name: string;
  code: string;
  branchName: string;
  branchId: string;
  disabled: boolean;
  status: EquipmentStatus;
  currentLocationName: string;
  currentLocationId: string;
  notes: string;
}

export enum EquipmentStatus {
  AllGood,
  PossibleProblem,
  BrokenAndWaiting,
  OutForRepairs,
}

export const StatusToLabelMapping: Record<EquipmentStatus, string> = {
  [EquipmentStatus.AllGood]: "All Good",
  [EquipmentStatus.PossibleProblem]: "Possible Problem",
  [EquipmentStatus.BrokenAndWaiting]: "Broken & Awaiting Repair",
  [EquipmentStatus.OutForRepairs]: "Out for Repair",
}
