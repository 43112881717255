import { Injectable } from '@angular/core';
import {FetchData} from '../../../helpers/fetch-data';
import {SearchService} from '../search-service';
import {LineItem, LineItemFilters} from '../../../line-item-entry/models/line-item.interface';


@Injectable({
  providedIn: null,
})
export class SearchLineItemDataService {

  constructor(
    searchService: SearchService
  ) {
    this.fetch = new FetchData((filters: LineItemFilters) =>
      searchService.searchLineItems(filters.searchString, filters.billingOnly)
    );
  }

  fetch: FetchData<LineItemFilters, LineItem[]>;

  input(searchString: string, billingOnly = false) {
    if (!searchString) {
      return;
    }
    this.fetch.input.next({searchString, billingOnly});
  }

  dispose = () => this.fetch.dispose();
}
