export class Page {
  size = 10;
  totalElements = 0;
  totalPages = 0;
  pageNumber = 0;
  hasFilter = false;
  filter = "";
}

export class MedicationsSuppliesSummaryListViewModel {
  medicationsSupplies: Array<MedicationsSuppliesSummary>;
  page = new Page();
  isOffline: boolean;
}

export class MedicationsSuppliesSummary {
  id: string;
  name: string;
  instructions: string;
  defaultQuantity: number;
  minimumQuantity: string;
  price: string;
  code: string;
  disabled: boolean;
  hasEqProtectDiscount: boolean;
  showOnEQProtect: boolean;
  showOnOverview: boolean;
  timePeriodType: string;
  medicationSupplyCategory: string;
  isControlledSubstance: boolean;
  isShownOnMedicationsTab: boolean;
  isInventory: boolean;
  causesDeath: boolean;
  category: string;
  inventoryBucketId: string;
  inventoryBucketName: string;
  inventoryConversionFactor: number;
  purchasePrice: number;
  hideNonBillingCode: boolean;
  minimumCost: number | null;
}

export const MEDICATION_CATEGORIES = [
  "Other",
  "BandageMaterial",
  "ControlledDrugs",
  "Eyes",
  "Fluids",
  "House",
  "Injectables",
  "IVShelf",
  "Orals",
  "Outside",
  "Plasma",
  "StorageShed",
  "Supplies",
  "Suture",
  "Topicals",
  "Vaccinations",
  "Wormers",
];
