import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import {Subject, Subscription} from 'rxjs';
import { PatientService } from 'src/app/patient-management/services/patient.service';
import {
  EditPatientMedicalRecord,
} from 'src/app/patient-management/models/patient.interface';
import {
  EmailListViewModel,
} from 'src/app/configuration-management/models/email.interface';
import { LayoutService } from 'src/app/layout/services/layout.service';
import { AccountService } from 'src/app/configuration-management/services/account.service';
import { LineItemInputService } from 'src/app/line-item-entry/services/line-item-input.service';
import { MedicalNoteTemplateService } from 'src/app/configuration-management/services/medical-note-template.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  ClientRecordStatus,
  PatientMedicalRecordBindingModel,
} from 'src/app/patient-management/models/patient.interface';
import {FormControl, Validators} from '@angular/forms';
import {Observable} from "rxjs/internal/Observable";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-edit-patient-record-dialog',
  templateUrl: './edit-patient-record.dialog.html',
  styleUrls: ['./edit-patient-record.dialog.scss'],
})
export class EditPatientMedicalRecordDialog implements OnInit {
  medicalNoteTemplates = new EmailListViewModel();
  communication: PatientMedicalRecordBindingModel;
  medicalRecord: PatientMedicalRecordBindingModel;
  reloadMedicalRecord = false;
  submitted = false;
  patientMedicalRecordId = '';
  selectedDate = new Date();
  updateDateSubject: Subject<Date> = new Subject<Date>();
  selectedStatus = ClientRecordStatus.Unfinished;
  statusOptions: [string, ClientRecordStatus][] = Object.keys(
    ClientRecordStatus
  )
    .filter((item) => isNaN(Number(item)))
    .map((name) => {
      const x: [string, ClientRecordStatus] = [name, ClientRecordStatus[name]];
      return x;
    });
  assigneeIds = new FormControl([], {validators: [Validators.required]});
  filteredRecentMedications: Observable<string[]>;
  recentMedicationControl = new FormControl();
  selectedRecentMedication = '';
  recentMedications: string[] = [];
  subscription: Subscription = new Subscription();

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10vh',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '3',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
    ],
    sanitize: true,
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [
      ['heading'],
      ['link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule'],
      ['clearFormatting', 'toggleEditorMode'],
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<EditPatientMedicalRecordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: EditPatientMedicalRecord,
    public patientService: PatientService,
    public layoutService: LayoutService,
    public accountService: AccountService,
    public lineItemInputService: LineItemInputService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public medicalNoteTemplateService: MedicalNoteTemplateService
  ) {}

  ngOnInit() {
    this.getMedicalRecord();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getMedicalRecord() {
    const medicalRecordObservable: Observable<PatientMedicalRecordBindingModel> = this.lineItemInputService.getMedicalRecord(this.data.medicalRecordId);

    this.subscription.add(medicalRecordObservable.subscribe((res) => {
      this.medicalRecord = res;
      this.selectedDate = new Date(res.createdTimestamp);
      this.selectedStatus = this.medicalRecord.status;
      this.assigneeIds.patchValue(res.assigneeIds);
    }));

    this.subscription.add(medicalRecordObservable.subscribe((res) => {
      this.getRecentMedications(res.patientId);
    }));
  }

  resetDate(date) {
    this.updateDateSubject.next(this.selectedDate);
  }

  submit() {
    this.assigneeIds.markAsTouched();
    if (!this.assigneeIds.valid) {
      return;
    }
    if (this.medicalRecord.emailTemplateId === null) {
      this.communication = new PatientMedicalRecordBindingModel();
      this.communication = this.medicalRecord;
    } else {
      this.communication.textBody = this.communication.textBody.replace(
        /contenteditable="true"/g,
        ''
      );
      this.communication.htmlBody = this.communication.htmlBody.replace(
        /contenteditable="true"/g,
        ''
      );
    }
    // submit but do not send comm
    this.communication.sendEmail = false;
    this.communication.createdTimestamp = this.selectedDate;
    this.communication.emailTemplateId = this.medicalRecord.id;
    this.communication.assigneeIds = this.assigneeIds.value;
    this.communication.status = this.selectedStatus;
    this.lineItemInputService
      .updateMedicalRecord(
        this.communication
      )
      .subscribe(
        (res) => {
          this.submitted = false;
          this.patientMedicalRecordId = '';
          this.dialogRef.close(true);
          this.snackBar.open('Medical record was updated.', 'Success', {
            duration: 2000,
          });
        },
        (error) => {
          this.snackBar.open('Medical record was not updated.', 'Warning', {
            duration: 2000,
          });
        }
      );
  }

  setMedicalNote(model: PatientMedicalRecordBindingModel) {
    this.communication = model;
  }

  getRecentMedications(patientId: string) {
    this.subscription.add(this.patientService.getPatientRecentMedications(patientId).subscribe((result) => {
      this.recentMedications = result.length > 0 ? result : ['None'];

      this.filteredRecentMedications = this.recentMedicationControl.valueChanges.pipe(
        startWith(''),
        map((search) => (search ? this._filterRecentMedications(search) : this.recentMedications.slice()))
      );
    }));
  }

  copyRecentMedicationToClipboard(medication: string) {
    this.selectedRecentMedication = medication;
    if (medication == 'None') {
      return;
    }
    navigator.clipboard.writeText(medication);
    this.snackBar.open('Copied To Clipboard', 'Dismiss', { duration: 2000 });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private _filterRecentMedications(search: string): string[] {
    const filterValue = search.toLowerCase();
    return this.recentMedications.filter(
      (option) => option.toLowerCase().includes(filterValue)
    );
  }
}
