import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LineItemInputService} from '../../../line-item-entry/services/line-item-input.service';
import {LineItem} from 'src/app/line-item-entry/models/line-item.interface';
import {SearchService} from '../search-service';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-search-line-items',
  templateUrl: './search-line-items.component.html',
  styleUrls: ['./search-line-items.component.scss']
})
export class SearchLineItemsComponent implements OnInit, OnDestroy {

  @Input() lineItem: LineItem;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() billingOnly = false;
  @Output() selectedLineItem = new EventEmitter<LineItem>();

  lineItemSearch = new FormControl({ value: '', disabled: this.disabled });
  filteredOptions: LineItem[] = [];

  subscriptions: Subscription;


  constructor(
    public lineItemInputService: LineItemInputService,
    public searchService: SearchService
  ) { }

  ngOnInit() {
    if (this.lineItem) {
      this.lineItemSearch = new FormControl({ value: this.lineItem.name, disabled: this.disabled });
    }

    this.subscriptions = this.lineItemSearch.valueChanges.pipe(debounceTime(500)).subscribe(value => this.getLineItems(value));
    this.subscriptions.add(this.searchService.searchLineItemsResultObservable.subscribe(this.setLineItems.bind(this)));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getLineItems(value: string) {
    this.searchService.searchLineItems(value, this.billingOnly);
  }

  setLineItems(results: LineItem[]) {
    if (results) {
      this.filteredOptions = results;
    }
  }


  selectLineItem(lineItem: LineItem) {
    const currentLineItemName = lineItem.name;
    this.lineItemSearch.setValue(currentLineItemName);
    this.lineItem = lineItem;
    this.selectedLineItem.emit(lineItem);
  }
}
