import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {CalendarService} from '../../schedule/services/calendar.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {Patient, PatientMedicalRecordFilters} from '../../patient-management/models/patient.interface';
import {FormControl} from '@angular/forms';
import {Client} from '../../client-management/models/client.interface';
import {CalendarEvent, EventInfo, GoogleLocation, TIMESLOTS, WEEKDAYS} from '../../schedule/models/schedule.models';
import {Subscription} from 'rxjs';
import {CreateBeforeAfter, ExistingAppointment} from './appointment-search.model';
import {AppointmentViewComponent} from '../../schedule/calendar-grid/appointment-view/appointment-view.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from "@angular/router";
import PlaceResult = google.maps.places.PlaceResult;

export class AppointmentSearchData {
  client: Client;
  patient: Patient;
}

@Component({
  selector: 'app-appointment-search',
  templateUrl: './appointment-search.component.html',
  styleUrls: ['./appointment-search.component.scss']
})
export class AppointmentSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<AppointmentSearchComponent>,
    public snackBar: MatSnackBar,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public calendarService: CalendarService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: AppointmentSearchData
  ) {
    iconRegistry.addSvgIcon('checkmark', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/checkmark.svg'));
  }

  protected readonly WEEKDAYS = WEEKDAYS;
  protected readonly TIMESLOTS = TIMESLOTS;
  protected readonly CreateBeforeAfter = CreateBeforeAfter;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource<ExistingAppointment>();
  displayedColumns = ['date', 'before', 'appointmentTime', 'after', 'doctor', 'fromAddress', 'distanceMiles', 'actions'];

  filters = new PatientMedicalRecordFilters();
  loading = true;
  overflow = false;

  timeSlots = TIMESLOTS;
  dateText = new FormControl();
  startDate: Date;
  endDate: Date;
  weekdays = new FormControl();
  startTime = new FormControl();
  endTime = new FormControl();
  durationControl = new FormControl();
  selectedDoctorFormControl = new FormControl();
  medicalRecordStatus = new FormControl();
  selectedClient = new Client();
  selectedPatient = new Patient();
  addressInput = new FormControl();
  location: GoogleLocation = undefined;
  distanceMilesControl = new FormControl();
  interactedAppointmentId: string;

  sortOrder: 'asc' | 'desc' | '';
  sortColumn: string;

  subscriptions: Subscription;

  async ngOnInit() {
    this.clearFilters();
    if (this.data.patient) {
      this.setPatient(this.data.patient);
    } else if (this.data.client) {
      this.setClient(this.data.client);
    }
  }

  ngAfterViewInit() {
    this.onFilterChanged();
  }

  ngOnDestroy() {
    // this.subscriptions.unsubscribe();
  }

  onFilterChanged = () => {
    this.getAppointments();
  }

  isInteracted(calendarEvent: CalendarEvent) {
    return calendarEvent.id === this.interactedAppointmentId ? 'interacted' : '';
  }

  clearFilters() {
    this.startDate = undefined;
    this.endDate = undefined;
    this.weekdays.setValue(WEEKDAYS.slice(1, 6).map(day => day.name));
    this.startTime.setValue(32); // 8AM
    this.endTime.setValue(68); // 5PM
    this.dateText.setValue(undefined, {emitEvent: false});
    this.durationControl.setValue(undefined);
    this.selectedDoctorFormControl.setValue([], {emitEvent: false});
    this.medicalRecordStatus.setValue(undefined, {emitEvent: false});
    this.distanceMilesControl.setValue(undefined, {emitEvent: false});
    this.addressInput.setValue(undefined);
    this.location = undefined;
    this.onFilterChanged();
  }

  getAppointments = () => {
    try {
      this.loading = true;
      this.interactedAppointmentId = undefined;
      if (!this.selectedDoctorFormControl.value && !this.location.placeId && !this.location.placeId && !this.startDate && !this.endDate) {
        return;
      }

      this.calendarService.searchAppointmentSlots(
        this.startDate,
        this.endDate,
        this.startTime.value,
        this.endTime.value,
        this.selectedDoctorFormControl.value,
        this.weekdays.value,
        this.location,
        this.distanceMilesControl.value,
        this.durationControl.value)
        .subscribe(res => {
          res = res.map(item => {
            item.date = new Date(item.date);
            return item;
          });
          this.loading = false;
          this.dataSource.data = res.slice(0, 200);
          this.overflow = res.length > 200;
        },
          (err) => {
          this.dataSource.data = [];
          this.loading = false;
          this.snackBar.open('There was an error loading appointments', 'Dismiss', {duration: 5000});
          });
    } catch (e) {
      console.log(e);
      this.dataSource.data = [];
      this.loading = false;
    }
  }

  setDate(event: any) {
    this.startDate = event.target.value.begin;
    this.endDate = event.target.value.end;
  }

  setClient(event: Client) {
    this.selectedClient = new Client();
    this.selectedPatient = new Patient();
    // todo clean up if not using this part of the googleMapsAPI
    // this.geocoder.geocode({ address: event.physicalAddress }).subscribe((results: any[]) => {
    //   if (results[0]) {
    //     const place = results[0] as PlaceResult;
    //     this.clientAddress = place;
    //     console.log('Place', this.clientAddress);
    //   }
    // });

    setTimeout(() => {
      this.selectedClient = event;
    }, 100);
  }

  setPatient(event: Patient) {
    this.selectedPatient = event;
    this.selectedClient = {id: event.clientId, name: event.clientName} as Client;
  }

  onAutocompleteFrom(place: PlaceResult) {
    console.log('autocomplete', place);
    this.location = new GoogleLocation(place);
    console.log(this.location.latitude, this.location.longitude);
  }

  openSchedulePopup(appointment: ExistingAppointment, createBeforeAfter: CreateBeforeAfter) {
    this.interactedAppointmentId = appointment.id;
    const eventInfo = {
      fromExistingAppointment: appointment,
      existingAppointmentFilters: {
        createBeforeAfter,
        location: this.location,
      },
      timeSlot: undefined,
      calendarEvent: undefined,
      date: undefined,
      doctor: undefined,
      client: this.selectedClient,
      patient: this.selectedPatient,
      mouseX: undefined,
      mouseY: undefined
    } as EventInfo;

    const dialogRef = this.dialog.open(AppointmentViewComponent, {
      width: '900px',
      height: 'auto',
      disableClose: true,
      data: {
        eventInfo,
      },
      panelClass: 'panel-wide-calendar-dialog',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAppointments();
      }
    });
  }

  openSchedule(calendarEvent: CalendarEvent) {
    this.interactedAppointmentId = calendarEvent.id;
    const url = this.router.createUrlTree(['schedule'], {
      queryParams: {
        date: calendarEvent.date.toLocaleDateString(),
        eventId: calendarEvent.id
      }
    });
    window.open('/#' + this.router.serializeUrl(url), '_blank');
  }

  close() {
    this.dialogRef.close();
  }
}
