import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ClientsFilters, InvoicesFilters, PatientsFilters, ListCommunicationsClientModel, ClientEmailList, ListCommunicationsPatientModel, StatementFilters, ListCommunicationsStatementModel, ListCommunicationsAppointmentModel } from '../models/list-communications.interface';
import { EmailViewModel } from 'src/app/configuration-management/models/email.interface';
import {
  MedicalRecordPage,
  PatientMedicalRecordFilters,
  PatientMedicalRecordViewModel
} from "../../patient-management/models/patient.interface";



@Injectable({
    providedIn: 'root',
  })
  export class ListCommunicationsService extends BaseService {

    // Queries
    public getInvoices(filters: InvoicesFilters): Observable<any> {

        const route = `${this.apiUrl}/ListCommunications/Invoices`;
        const body = JSON.stringify(filters);
        return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public getStatements(filters: StatementFilters): Observable<ListCommunicationsStatementModel[]> {

        const route = `${this.apiUrl}/ListCommunications/Statements`;
        const body = JSON.stringify(filters);
        return this.http.post<ListCommunicationsStatementModel[]>(route, body, this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public getAppointments(filters: PatientsFilters): Observable<ListCommunicationsAppointmentModel[]> {

        const route = `${this.apiUrl}/ListCommunications/Appointments`;
        const body = JSON.stringify(filters);
        return this.http.post<ListCommunicationsAppointmentModel[]>(route, body, this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public getPatients(filters: PatientsFilters): Observable<ListCommunicationsPatientModel[]> {

        const route = `${this.apiUrl}/ListCommunications/Patients`;
        const body = JSON.stringify(filters);
        return this.http.post<ListCommunicationsPatientModel[]>(route, body, this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public getVaccines(filters: PatientsFilters): Observable<ListCommunicationsPatientModel[]> {

        const route = `${this.apiUrl}/ListCommunications/Vaccines`;
        const body = JSON.stringify(filters);
        return this.http.post<ListCommunicationsPatientModel[]>(route, body, this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public getClients(filters: ClientsFilters): Observable<ListCommunicationsClientModel[]> {

        const route = `${this.apiUrl}/ListCommunications/Clients`;
        const body = JSON.stringify(filters);
        return this.http.post<ListCommunicationsClientModel[]>(route, body, this.headers()).pipe(map(response => {
            return response;
        }));
    }

  public getMedicalRecords(filters: PatientMedicalRecordFilters): Observable<MedicalRecordPage> {

    const route = `${this.apiUrl}/ListCommunications/MedicalRecords`;
    const body = JSON.stringify(filters);
    return this.http.post<MedicalRecordPage>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public exportMedicalRecords(filters: PatientMedicalRecordFilters) {

    const route = `${this.apiUrl}/ListCommunications/ExportMedicalRecords`;
    const body = JSON.stringify(filters);
    return this.http.post(route, body, this.fileHeaders());
  }

    public sendEmails(emailId: string, emailDetails: ClientEmailList[], type: string ): any  {

        const route = `${this.apiUrl}/ListCommunications/${emailId}/SendEmails/${false}`;
        const body = JSON.stringify({ startDate: emailDetails[0].startDate, endDate: emailDetails[0].endDate , emailList: emailDetails, type });


        return this.http.post(route, body , this.fileHeaders()).pipe(map(response => {
            return new Blob([response], { type: 'application/pdf' });
        }));
    }

    public printEmails(emailId: string, emailDetails: ClientEmailList[], type: string ): any  {

        const route = `${this.apiUrl}/ListCommunications/${emailId}/SendEmails/${true}`;
        const body = JSON.stringify({ startDate: emailDetails[0].startDate, endDate: emailDetails[0].endDate , emailList: emailDetails, type });


        return this.http.post(route, body , this.fileHeaders()).pipe(map(response => {
            return new Blob([response], { type: 'application/pdf' });
        }));
    }

    public getEmailPreview(emailId: string, emailDetails: ClientEmailList): Observable<EmailViewModel> {

        const route = `${this.apiUrl}/ListCommunications/${emailId}/Preview`;
        const body = JSON.stringify(emailDetails);

        return this.http.post<EmailViewModel>(route, body, this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public getStatementsEmailPreview(clientId: string, statementId: string): Observable<EmailViewModel> {
        const route = `${this.apiUrl}/ListCommunications/PreviewStatementsEmail/${clientId}/statement/${statementId}`;

        return this.http.get<EmailViewModel>(route, this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public getSingleInvoiceEmailPreview(invoiceId: string): Observable<EmailViewModel> {
        const route = `${this.apiUrl}/ListCommunications/PreviewInvoiceEmail/${invoiceId}`;

        return this.http.get<EmailViewModel>(route, this.headers()).pipe(map(response => {
            return response;
        }));
    }

    public printStatementsEmail(clientId: string, statementId: string ): any  {

        const route = `${this.apiUrl}/ListCommunications/PrintStatementsEmail/${clientId}/statement/${statementId}`;


        return this.http.get(route , this.fileHeaders()).pipe(map(response => {
            return new Blob([response], { type: 'application/pdf' });
        }));
    }

    public printInvoiceEmail(invoiceId: string ): any  {

        const route = `${this.apiUrl}/ListCommunications/PrintInvoiceEmail/${invoiceId}`;


        return this.http.get(route , this.fileHeaders()).pipe(map(response => {
            return new Blob([response], { type: 'application/pdf' });
        }));
    }

    public printEstimateEmail(estimateId: string ): any  {
        const route = `${this.apiUrl}/ListCommunications/PrintEstimateEmail/${estimateId}`;

        return this.http.get(route , this.fileHeaders()).pipe(map(response => {
            return new Blob([response], { type: 'application/pdf' });
        }));
    }

    getStatementTaskFile(statementTaskId: string): any {
        const route = `${this.apiUrl}/ListCommunications/${statementTaskId}/ViewQueuedFile`;
        return this.http.get(route, this.fileHeaders()).pipe(map(res => {
          return new Blob([res], { type: 'application/pdf' });
        }));
    }
}
