import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {RequestNote} from '../../requests-results/models/request.interface';
import {RequestService} from '../../requests-results/services/request.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation.dialog';
import {MatDialog} from '@angular/material/dialog';
import {formatDate} from '@angular/common';
import {AccountService} from '../../configuration-management/services/account.service';

@Component({
  selector: 'app-internal-notes-editor',
  templateUrl: './internal-notes-editor.component.html',
  styleUrls: ['./internal-notes-editor.component.scss']
})
export class InternalNotesEditorComponent implements OnInit {
  resultsForm: FormGroup;

  @Input() internalNotesLabel = 'Internal Notes:';
  @Input() appendToInternalNotesLabel = 'Append to Internal Notes:';
  @Input() requestId: string;
  @Input() requestNotes: RequestNote[];

  @Output() noteChange = new EventEmitter<RequestNote[]>();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public requestService: RequestService,
    public accountService: AccountService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  notesChanged(): void {
    this.noteChange.emit(this.requestNotes);
    this.buildForm();
  }

  buildForm(): void {
    // Rebuild the form group
    this.resultsForm = this.formBuilder.group({
      notes: this.formBuilder.array(
        this.requestNotes.map(note => note.text)
      ),
      newNote: [''],
    });

    // Ensure all existing note boxes are disabled
    for (const control of this.getNoteControls()) {
      control.disable();
    }
  }

  addNote(): void {
    const newNoteText = this.resultsForm.controls.newNote.value;

    // Allow users to edit notes that haven't been pushed to the server yet
    if (this.requestId === undefined) {
      const newNote = new RequestNote();
      newNote.text = newNoteText;
      newNote.authorId = this.accountService.currentUserValue.id;
      newNote.authorFullName = this.accountService.currentUserValue.firstName + ' '
        + this.accountService.currentUserValue.lastName;
      newNote.dateCreated = newNote.dateModified = new Date(Date.now());

      this.requestNotes.splice(0, 0, newNote);
      this.notesChanged();
      return;
    }

    // this.requestService.addRequestNote(this.requestId, newNoteText).subscribe(newNote => {
    //     this.requestNotes.splice(0, 0, newNote);
    //     this.notesChanged();
    //
    //     this.snackBar.open('Request note has been added', 'Success', {
    //       duration: 2000,
    //     });
    //   },
    //   error => {
    //     this.snackBar.open('Request note could not be added', 'Error', {
    //       duration: 2000,
    //     });
    //   });
  }

  updateNote(noteIndex: number) {
    // The user has to click this button twice:
    // first to start editing, then to save their changes

    const noteControl = this.getNoteControls()[noteIndex];
    if (noteControl.disabled) {
      noteControl.enable();
      return;
    }

    noteControl.disable();

    const noteId = this.requestNotes[noteIndex].id;
    const newNoteText = noteControl.value;

    // Allow users to edit notes that haven't been pushed to the server yet
    if (noteId === undefined) {
      this.requestNotes[noteIndex].text = newNoteText;
      this.notesChanged();
      return;
    }

    // this.requestService.updateRequestNote(noteId, newNoteText).subscribe(updatedNote => {
    //     this.requestNotes[noteIndex].text = updatedNote.text;
    //     this.notesChanged();
    //
    //     this.snackBar.open('Request note has been updated', 'Success', {
    //       duration: 2000,
    //     });
    //   },
    //   error => {
    //     this.snackBar.open('Request note could not be updated', 'Error', {
    //       duration: 2000,
    //     });
    //   });
  }

  deleteNote(noteIndex: number) {
    const note = this.requestNotes[noteIndex];

    // Confirm before deletion
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        header: 'Delete Note',
        body: `This internal note by ${note.authorFullName} on ${this.formatDateMedium(note.dateCreated)} will be deleted.`,
      }
    });

    // Allow users to edit notes that haven't been pushed to the server yet
    if (note.id === undefined) {
      this.requestNotes.splice(noteIndex, 1);
      this.notesChanged();
      return;
    }

    dialogRef.afterClosed().subscribe(deleteConfirmed => {
      if (deleteConfirmed) {
        // this.requestService.deleteRequestNote(note.id).subscribe(_ => {
        //     this.requestNotes.splice(noteIndex, 1);
        //     this.notesChanged();
        //
        //     this.snackBar.open('Request note was deleted', 'Success', {
        //       duration: 2000,
        //     });
        //   },
        //   error => {
        //     this.snackBar.open('Request note could not be deleted', 'Error', {
        //       duration: 2000,
        //     });
        //   });
      } else {
        this.snackBar.open('Operation cancelled.', '', {
          duration: 2000,
        });
      }
    });
  }

  getNoteControls(): AbstractControl[] {
    return (this.resultsForm.controls.notes as FormArray).controls;
  }

  formatDateMedium(date: Date): string {
    if (date === undefined) {
      return '';
    }
    return formatDate(date, 'medium', 'en-US');
  }

  getNoteHeader(note: RequestNote): string {
    return `${note.authorFullName} at ${this.formatDateMedium(note.dateCreated)} PT`;
  }

  getNoteAsPlaintext(note: RequestNote): string {
    return `${this.getNoteHeader(note)}: ${note.text}`;
  }

}
