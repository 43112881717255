import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dbContext } from '../../../shared/offline/offline-db.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { storage } from 'src/app/shared/local-storage/local-storage.service';

export class OfflineDetails {
  name: string;
  date: Date;
  count: number;
  tableName: string;
}

@Component({
  selector: 'app-sync-dialog',
  templateUrl: './sync.dialog.html',
  styleUrls: ['./sync.dialog.scss']
})
export class SyncDialogComponent implements OnInit {

  offlineDetails: OfflineDetails[] = [
    { name: 'line-items-last-updated', date: new Date(), count: 0, tableName: 'Line Items' },
    { name: 'doctors-last-updated', date: new Date(), count: 0, tableName: 'Doctors' },
    { name: 'inventory-locations-last-updated', date: new Date(), count: 0, tableName: 'Inventory Locations' },
    { name: 'discounts-last-updated', date: new Date(), count: 0, tableName: 'Discounts' },
    { name: 'patients-last-updated', date: new Date(), count: 0, tableName: 'Patients' },
    { name: 'clients-patients-last-updated', date: new Date(), count: 0, tableName: 'Client Search' },
    { name: 'drafts', date: new Date(), count: 0, tableName: 'Drafts' },
    { name: 'medical-templates-last-updated', date: new Date(), count: 0, tableName: 'Medical Note Templates' }];

  lastSync: string;

  constructor(
    public dialogRef: MatDialogRef<SyncDialogComponent>,
    public snackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.lastSync = storage.getItem<string>('last-sync', '');
    this.getData();
  }

  getData() {

    this.offlineDetails.forEach(table => {
      table.date = new Date(storage.getItem<string>(table.name, '1990-01-01T00:00:00.000000-08:00'));
      switch (table.name) {
        case 'line-items-last-updated':

          dbContext.lineItems.count().then(x => {
            this.offlineDetails.find(y => y.name === table.name).count = x;
          });

          break;

        case 'doctors-last-updated':

          dbContext.doctors.count().then(x => {
            this.offlineDetails.find(y => y.name === table.name).count = x;
          });

          break;

        case 'inventory-locations-last-updated':

          dbContext.inventoryLocations.count().then(x => {
            this.offlineDetails.find(y => y.name === table.name).count = x;
          });

          break;
        case 'discounts-last-updated':

          dbContext.discounts.count().then(x => {
            this.offlineDetails.find(y => y.name === table.name).count = x;
          });

          break;
        case 'clients-patients-last-updated':

          dbContext.clientSearch.count().then(x => {
            this.offlineDetails.find(y => y.name === table.name).count = x;
          });

          break;
        case 'drafts':

          dbContext.drafts.count().then(x => {
            this.offlineDetails.find(y => y.name === table.name).count = x;
          });

          break;
        case 'patients-last-updated':

          dbContext.patients.count().then(x => {
            this.offlineDetails.find(y => y.name === table.name).count = x;
          });
          break;
          case 'medical-templates-last-updated':

            dbContext.emailTemplates.count().then(x => {
              this.offlineDetails.find(y => y.name === table.name).count = x;
            });
            break;
        default:
          break;
      };

    });


  }

  clear(table: OfflineDetails) {

    this.snackBar.open('Clearing ' + table.tableName + ' table, please wait.', 'Clearing...', {
      duration: 2000,
    });
    switch (table.name) {
      case 'line-items-last-updated':


        dbContext.lineItems.clear().then(x => {
          this.snackBar.open('Cleared ' + table.tableName + ' table, please reload.', 'Success', {
            duration: 2000,
          });
        });

        break;

      case 'doctors-last-updated':

        dbContext.doctors.clear().then(x => {
          this.snackBar.open('Cleared ' + table.tableName + ' table, please reload.', 'Success', {
            duration: 2000,
          });
        });


        break;

      case 'inventory-locations-last-updated':

        dbContext.inventoryLocations.clear().then(x => {
          this.snackBar.open('Cleared ' + table.tableName + ' table, please reload.', 'Success', {
            duration: 2000,
          });
        });


        break;
      case 'discounts-last-updated':

        dbContext.discounts.clear().then(x => {
          this.snackBar.open('Cleared ' + table.tableName + ' table, please reload.', 'Success', {
            duration: 2000,
          });
        });


        break;
      case 'clients-patients-last-updated':

        dbContext.clientSearch.clear().then(x => {
          this.snackBar.open('Cleared ' + table.tableName + ' table, please reload.', 'Success', {
            duration: 2000,
          });
        });


        break;
      case 'drafts':
        dbContext.drafts.clear().then(x => {
          this.snackBar.open('Cleared ' + table.tableName + ' table, please reload.', 'Success', {
            duration: 2000,
          });
        });


        break;
        case 'patients-last-updated':
          dbContext.patients.clear().then(x => {
            this.snackBar.open('Cleared ' + table.tableName + ' table, please reload.', 'Success', {
              duration: 2000,
            });
          });


        break;
        case 'medical-templates-last-updated':
          dbContext.patients.clear().then(x => {
            this.snackBar.open('Cleared ' + table.tableName + ' table, please reload.', 'Success', {
              duration: 2000,
            });
          });

          break;
      default:
        break;
    };
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
