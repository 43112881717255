import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ReferringDoctor } from '../models/referring-doctor.interface';

@Injectable({
  providedIn: 'root',
})
export class ReferringDoctorService extends BaseService {

  public index(): Observable<ReferringDoctor[]> {

    const route = `${this.apiUrl}/ReferringDoctor`;

    return this.http.get<ReferringDoctor[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public create(model: ReferringDoctor): Observable<ReferringDoctor> {
    const route = `${this.apiUrl}/ReferringDoctor/Create`;
    const body = JSON.stringify(model);

    return this.http.post<ReferringDoctor>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: ReferringDoctor): Observable<ReferringDoctor> {
    const route = `${this.apiUrl}/ReferringDoctor/Update`;
    const body = JSON.stringify(model);

    return this.http.post<ReferringDoctor>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }


}
