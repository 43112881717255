import {Injectable} from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import {LineItem, LineItemCategory, ReminderOverrideView} from '../../line-item-entry/models/line-item.interface';
import {Subject} from 'rxjs';
import {LocationSummary} from '../../configuration-management/models/locations.interface';
import {Client} from '../../client-management/models/client.interface';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseService {

  public searchClientsResultObservable = new Subject<Client[]>();
  public searchLocationResultObservable = new Subject<LocationSummary[]>();

  public searchItemsNoGroups(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchLineItemsNoGroups/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchLineItems(searchString: string, billingOnly = false): Observable<LineItem[]> {
    if (!searchString) {
      return;
    }
    const route = `${this.apiUrl}/Search/LineItems`;
    const defaultOptions = this.headers();
    return this.http.get<LineItem[]>(route, {headers: defaultOptions.headers, params:
        {searchString, billingOnly: JSON.stringify(billingOnly)}
    });
  }

  public overviewReminderOverrides(itemId: string, lineItemCategory: string) {
    if (!itemId || !lineItemCategory) {
      return;
    }
    let query = {};
    switch (lineItemCategory) {
      case LineItemCategory.ServiceCode:
        query = {serviceId: itemId};
        break;
      case LineItemCategory.MedicationsAndSupplies:
        query = {medicationSupplyId: itemId};
        break;
      case LineItemCategory.Labs:
        query = {labId: itemId};
        break;
      case LineItemCategory.Group:
        query = {groupId: itemId};
        break;
      case LineItemCategory.GroupItem:
        query = {groupItemId: itemId};
        break;
    }
    const route = `${this.apiUrl}/Search/OverviewReminderOverrides`;
    const defaultOptions = this.headers();
    return this.http.get<ReminderOverrideView[]>(route, {headers: defaultOptions.headers, params: query});
  }

  public searchClients(searchString: string) {

    const route = `${this.apiUrl}/Search/Clients`;
    const defaultOptions = this.headers();
    return this.http.get<Client[]>(route, {headers: defaultOptions.headers, params:
        {searchString}
    }).subscribe(res => {
      this.searchClientsResultObservable.next(res);
    });
  }

  public searchLocations(searchString: string) {

    const route = `${this.apiUrl}/Search/Locations`;
    const defaultOptions = this.headers();
    this.http.get<LocationSummary[]>(route, {headers: defaultOptions.headers, params:
        {searchString}
    }).subscribe(res => {
      this.searchLocationResultObservable.next(res);
    });
  }

  public searchFluids(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchFluids/${encodeURIComponent(searchString)}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchVitalGroups(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchVitalGroups/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public searchVitals(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchVitals/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchDailyCare(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchDailyCare/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchLabResults(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchLabResults/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }
  public searchLabs(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchLabs/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public searchServiceCodes(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchServiceCodes/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public searchMedicationsAndSupplies(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchMedicationsAndSupplies/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public searchGroups(searchString: string): Observable<any[]> {

    const route = `${this.apiUrl}/Search/SearchGroups/${searchString}`;

    return this.http.get<any[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


}
