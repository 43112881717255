export interface ConfigModel {
  environmentConfig: string;
  apiUrl: string;
  staging: boolean;
  qa: boolean;
  demo: boolean;
  prod: boolean;
  dev: boolean;
  pwa: boolean;

  featureFlags: any;
}


export enum FeatureFlag {
  exampleFeatureFlag = 'exampleFeatureFlag',
  estimatesFlag = 'estimates',
}
