import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { EquipmentSummary } from '../models/equipment.interface';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService extends BaseService {
  public index(): Observable<any> {
    const route = `${this.apiUrl}/Equipment`;

    return this.http.get<EquipmentSummary>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public create(model: EquipmentSummary): Observable<EquipmentSummary> {
    const route = `${this.apiUrl}/Equipment`;
    const body = JSON.stringify(model);

    return this.http.post<EquipmentSummary>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: EquipmentSummary): Observable<EquipmentSummary> {
    const route = `${this.apiUrl}/Equipment/Update`;
    const body = JSON.stringify(model);

    return this.http.post<EquipmentSummary>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
