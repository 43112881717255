import {Component, Injector, Input, OnInit, OnDestroy} from '@angular/core';
import {Branch} from '../../configuration-management/models/branch.interface';
import {BranchService} from '../../configuration-management/services/branch.service';
import {FormControl, FormGroupDirective} from '@angular/forms';
import {getNestedFormControl} from '../utils/form.util';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-select-branch',
  templateUrl: './select-branch.component.html',
  styleUrls: ['./select-branch.component.scss']
})
export class SelectBranchComponent implements OnInit, OnDestroy {
  branches: Branch[];
  formSearch: FormControl = new FormControl('');
  selectedBranchNames: string[];
  private subscriptions: Subscription = new Subscription();

  @Input() control: FormControl;
  @Input() controlName: string;
  @Input() label = 'Branch';
  @Input() allowAll = false;
  @Input() allowOther = false;
  @Input() allowMultiple = false;  // changed from isMultiple

  private rootFormGroup: FormGroupDirective;

  constructor(private branchService: BranchService, private injector: Injector) { }

  ngOnInit() {
    if (this.controlName) {
      this.rootFormGroup = this.injector.get(FormGroupDirective);
      this.control = getNestedFormControl(this.rootFormGroup.form, this.controlName);
      console.log(this.control);
    }

    this.subscriptions.add(
      this.control.valueChanges.subscribe(this.updateSelectedBranchNames.bind(this))
    );

    this.branchService.index().subscribe(res => {
      this.branches = res.sort((a, b) => a.name.localeCompare(b.name));
      this.updateSelectedBranchNames();
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private updateSelectedBranchNames() {
    if (!this.branches || !this.control.value) {
      this.selectedBranchNames = [];
      return;
    }

    const selected = this.branches.filter(branch =>
      Array.isArray(this.control.value)
        ? this.control.value.includes(branch.id)
        : this.control.value === branch.id
    );
    this.selectedBranchNames = selected.map(branch => branch.name);

    // Add "Other" to the selected names if it's selected
    if (Array.isArray(this.control.value) && this.control.value.includes('')) {
      this.selectedBranchNames.push('Other');
    } else if (this.control.value === '') {
      this.selectedBranchNames = ['Other'];
    }
  }
}
