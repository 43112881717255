import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { CalendarEvent, GoogleLocation, Calendar } from '../models/schedule.models';
import { start } from 'repl';
import { GoogleDistance } from '../models/google-location.models';
import { default as _rollupMoment } from 'moment';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class AddressGateCodesService extends BaseService {

  findGateCodes(placeId: string): Observable<string> {
    const route = `${this.apiUrl}/AddressGateCodes/${placeId}`;
    return this.http.get<string>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

}
