import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-formatted-html-content',
  templateUrl: './formatted-html-content.component.html',
  styleUrls: ['./formatted-html-content.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class FormattedHtmlContentComponent {
  @Input() htmlContent: string;
  @Input() styleClass: string;
}
