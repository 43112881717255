import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/helpers/base.service';
import { Observable } from 'rxjs';
import { InvoiceBase } from '../models/invoice.interface';
import { map } from 'rxjs/operators';
import { LineItemSubmission } from '../models/line-item.interface';


@Injectable({
  providedIn: 'root'
})
export class EstimateService extends BaseService {
  public create(model: LineItemSubmission): Observable<InvoiceBase> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Estimate/Create`;

    // TODO: Use Estimate model
    return this.http.post<InvoiceBase>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: LineItemSubmission, estimateId: string): Observable<InvoiceBase> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Estimate/${estimateId}/Update`;

    // TODO: Use Estimate model
    return this.http.post<InvoiceBase>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public publish(model: LineItemSubmission, estimateId: string): Observable<string> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Estimate/${estimateId}/Publish`;

    return this.http.post<string>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public delete(estimateId: string): Observable<null> {
    const route = `${this.apiUrl}/Estimate/${estimateId}/Delete`;
    return this.http.delete<null>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
