import { UserAccount } from '../../configuration-management/models/user.interface';
import { Branch } from '../../configuration-management/models/branch.interface';
import { Client } from '../../client-management/models/client.interface';
import { Patient } from '../../patient-management/models/patient.interface';
import { EquipmentSummary } from '../../configuration-management/models/equipment.interface';
import { Note } from '../../shared/notes/note.model';
import { ReferringDoctor } from 'src/app/configuration-management/models/referring-doctor.interface';
import PlaceResult = google.maps.places.PlaceResult;
import {
  CreateBeforeAfter,
  ExistingAppointment,
  ExistingAppointmentParams
} from '../../shared/appointment-search/appointment-search.model';

export function addMinutes(time, minsToAdd) {
    function D(J) { return (J < 10 ? '0' : '') + J; };
    const piece = time.split(':');
    const mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    // tslint:disable-next-line: no-bitwise
    return D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60);
}

export class EventInfo {
    timeSlot: TimeSlot;
    calendarEvent: CalendarEvent;
    doctor = new UserAccount();
    client = new Client();
    patient = new Patient();
    date: Date;
    mouseX = '20px';
    mouseY = '40px';
    fromExistingAppointment: ExistingAppointment = undefined;
    existingAppointmentFilters: ExistingAppointmentParams;
}

export class EventInfoData {
  eventInfo: EventInfo;
}

export class CalendarEventClient {
    client = new Client();
    patients: CalendarEventPatient[];
    id: string;
}

export class CalendarEventPatient {
    patient = new Patient();
    id: string;
}

export class CalendarEventEquipment {
    equipment = new EquipmentSummary();
    id: string;
}

export class CalendarEventUser {
    user = new UserAccount();
    id: string;
}

export class Calendar {
    calendarEvents: CalendarEvent[];
    userAccounts: UserAccount[];
}

export class CalendarEvent {
    branch: Branch;
    clientIndex = 1;
    orderNumber: number;
    apptType: string;
    date: Date;
    startTime: string;
    endTime: string;
    startTimeHours: any;
    createdUserId: string;
    endTimeHours: any;
    time: number;
    location: string;
    address: string;
    reason = '';
    endName: string;
    equipmentIsNeeded: boolean;
    startName: string;
    conflicts: string[];
    name = '';
    width = '80px';
    left = '0px';
    doctor: string;
    type: string;
    height = '38px';
    id: string;
    isSingle = true;
    confirmationSent = false;
    isAmbulatory = false;
    googleLocation: GoogleLocation;
    isConfirmed = false;
    isCancelled = false;
    calendarEventType = 'Placeholder';
    techIsNeeded = false;
    noDoctorPreference = false;
    clients: CalendarEventClient[];
    doctors: CalendarEventUser[];
    techs: CalendarEventUser[];
    equipment: CalendarEventEquipment[];
    notes: Note[];
    createdUser: UserAccount;
    createdDate: Date;
    referringDoctor: ReferringDoctor;
    checkedIn = false;
    sorted = false;

    static Sort = (a: CalendarEvent, b: CalendarEvent) => {
      return ((new Date(a.date) as any) - (new Date(b.date) as any) || a.orderNumber - b.orderNumber);
    }
}

export class GoogleLocation {
    url: string;
    // tslint:disable-next-line: variable-name
    formatted_address = '';
    vicinity: string;
    address = '';
    placeId: string;
    // tslint:disable-next-line: variable-name
    place_id: string;
    gateCodes: string;
    latitude: number;
    longitude: number;
    constructor(place?: PlaceResult) {
      if (place) {
        this.url = place.url;
        this.formatted_address = place.formatted_address;
        this.vicinity = place.vicinity;
        this.address = place.formatted_address;
        this.placeId = place.place_id;
        this.place_id = place.place_id;
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
      }
    }
}

export class TimeSlot {
    time: string;
    name: string;
    slotName: string;
    timeInput: string;
    shift: string;
    isSwingShift: boolean;
}

export class CalendarEventType {
    displayName: string;
    name: string;
    time: number;
    timeDescription: string;
}

export class WeekDay {
    displayName: string;
    name: string;
    day: number;
}

export const WEEKDAYS: WeekDay[] = [
    { displayName: 'Sun', name: 'Sunday', day: 0 },
    { displayName: 'Mon', name: 'Monday', day: 1 },
    { displayName: 'Tues', name: 'Tuesday', day: 2 },
    { displayName: 'Wed', name: 'Wednesday', day: 3},
    { displayName: 'Thurs', name: 'Thursday', day: 4 },
    { displayName: 'Fri', name: 'Friday', day: 5 },
    { displayName: 'Sat', name: 'Saturday', day: 6},

];

export const CalendarEventTypes: CalendarEventType[] = [
    { displayName: 'Block', name: 'Block', time: .5, timeDescription: '30 min' },
    { displayName: 'Dental', name: 'Dental', time: 0.75, timeDescription: '45 min' },
    { displayName: 'Vaccination', name: 'Vaccination', time: 0.5, timeDescription: '30 min' },
    { displayName: 'Ultrasound', name: 'Ultrasound', time: 1.0, timeDescription: '1 hr' },
    { displayName: 'X-Ray', name: 'XRay', time: 1.0, timeDescription: '1 hr' },
    { displayName: 'Emergency', name: 'Emergency', time: 1.0, timeDescription: '1 hr' },
    { displayName: 'Client Appointment', name: 'ClientAppointment', time: 1.0, timeDescription: '1 hr' },
    { displayName: 'Accupuncture or Chiro', name: 'AccupunctureChiro', time: 0.75, timeDescription: '45 min' },
    { displayName: 'Drop Off', name: 'DropOff', time: 0.5, timeDescription: '30 min' },
    { displayName: 'MRI', name: 'MRI', time: 3.0, timeDescription: '3 hrs' },
    { displayName: 'Shockwave Therapy', name: 'ShockwaveTherapy', time: 1.0, timeDescription: '1 hr' },
    { displayName: 'Euthanasia', name: 'Euthanasia', time: 0.5, timeDescription: '30 min' },
    { displayName: 'Consultation', name: 'Consultation', time: 0.5, timeDescription: '30 min' },
    { displayName: 'Boarding', name: 'Boarding', time: 0.5, timeDescription: '30 min' },
    { displayName: 'Repro', name: 'Repro', time: 0.5, timeDescription: '30 min' },
    { displayName: 'Endoscopy', name: 'Endoscopy', time: 0.5, timeDescription: '30 min' },
    { displayName: 'Surgery', name: 'Surgery', time: 3.0, timeDescription: '3 hrs' },
    { displayName: 'Cold Laser', name: 'ColdLaser', time: 0.5, timeDescription: '30 mins' },
    { displayName: 'Transport', name: 'Transport', time: 0.5, timeDescription: '30 min' },
    { displayName: 'Travel Time', name: 'Travel', time: .5, timeDescription: '30 min' },
    { displayName: 'Time Off', name: 'TimeOff', time: 8.0, timeDescription: '8 hrs' },
];

export const TIMESLOTS: TimeSlot[] = [
    { time: 'time-1200AM', slotName: '12:00 AM', timeInput: '00:00:00', name: '12am', shift: 'night', isSwingShift: false},
    { time: 'time-1215AM', slotName: '12:15 AM', timeInput: '00:15:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-1230AM', slotName: '12:30 AM', timeInput: '00:30:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-1245AM', slotName: '12:45 AM', timeInput: '00:45:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0100AM', slotName: '1:00 AM', timeInput: '01:00:00', name: '1am', shift: 'night' , isSwingShift: false},
    { time: 'time-0115AM', slotName: '1:15 AM', timeInput: '01:15:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0130AM', slotName: '1:30 AM', timeInput: '01:30:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0145AM', slotName: '1:45 AM', timeInput: '01:45:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0200AM', slotName: '2:00 AM', timeInput: '02:00:00', name: '2am', shift: 'night' , isSwingShift: false},
    { time: 'time-0215AM', slotName: '2:15 AM', timeInput: '02:15:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0230AM', slotName: '2:30 AM', timeInput: '02:30:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0245AM', slotName: '2:45 AM', timeInput: '02:45:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0300AM', slotName: '3:00 AM', timeInput: '03:00:00', name: '3am', shift: 'night' , isSwingShift: false},
    { time: 'time-0315AM', slotName: '3:15 AM', timeInput: '03:15:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0330AM', slotName: '3:30 AM', timeInput: '03:30:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0345AM', slotName: '3:45 AM', timeInput: '03:45:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0400AM', slotName: '4:00 AM', timeInput: '04:00:00', name: '4am', shift: 'night' , isSwingShift: false},
    { time: 'time-0415AM', slotName: '4:15 AM', timeInput: '04:15:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0430AM', slotName: '4:30 AM', timeInput: '04:30:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0445AM', slotName: '4:45 AM', timeInput: '04:45:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0500AM', slotName: '5:00 AM', timeInput: '05:00:00', name: '5am', shift: 'night' , isSwingShift: false},
    { time: 'time-0515AM', slotName: '5:15 AM', timeInput: '05:15:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0530AM', slotName: '5:30 AM', timeInput: '05:30:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0545AM', slotName: '5:45 AM', timeInput: '05:45:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0600AM', slotName: '6:00 AM', timeInput: '06:00:00', name: '6am', shift: 'night' , isSwingShift: false},
    { time: 'time-0615AM', slotName: '6:15 AM', timeInput: '06:15:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0630AM', slotName: '6:30 AM', timeInput: '06:30:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0645AM', slotName: '6:45 AM', timeInput: '06:45:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0700AM', slotName: '7:00 AM', timeInput: '07:00:00', name: '7am', shift: 'night' , isSwingShift: false},
    { time: 'time-0715AM', slotName: '7:15 AM', timeInput: '07:15:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0730AM', slotName: '7:30 AM', timeInput: '07:30:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0745AM', slotName: '7:45 AM', timeInput: '07:45:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-0800AM', slotName: '8:00 AM', timeInput: '08:00:00', name: '8am', shift: 'day' , isSwingShift: false},
    { time: 'time-0815AM', slotName: '8:15 AM', timeInput: '08:15:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-0830AM', slotName: '8:30 AM', timeInput: '08:30:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-0845AM', slotName: '8:45 AM', timeInput: '08:45:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-0900AM', slotName: '9:00 AM', timeInput: '09:00:00', name: '9am', shift: 'day' , isSwingShift: false},
    { time: 'time-0915AM', slotName: '9:15 AM', timeInput: '09:15:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-0930AM', slotName: '9:30 AM', timeInput: '09:30:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-0945AM', slotName: '9:45 AM', timeInput: '09:45:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-1000AM', slotName: '10:00 AM', timeInput: '10:00:00', name: '10am', shift: 'day' , isSwingShift: false},
    { time: 'time-1015AM', slotName: '10:15 AM', timeInput: '10:15:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-1030AM', slotName: '10:30 AM', timeInput: '10:30:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-1045AM', slotName: '10:45 AM', timeInput: '10:45:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-1100AM', slotName: '11:00 AM', timeInput: '11:00:00', name: '11am', shift: 'day' , isSwingShift: false},
    { time: 'time-1115AM', slotName: '11:15 AM', timeInput: '11:15:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-1130AM', slotName: '11:30 AM', timeInput: '11:30:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-1145AM', slotName: '11:45 AM', timeInput: '11:45:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-1200PM', slotName: '12:00 PM', timeInput: '12:00:00', name: '12pm', shift: 'day' , isSwingShift: false},
    { time: 'time-1215PM', slotName: '12:15 PM', timeInput: '12:15:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-1230PM', slotName: '12:30 PM', timeInput: '12:30:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-1245PM', slotName: '12:45 PM', timeInput: '12:45:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-0100PM', slotName: '1:00 PM', timeInput: '13:00:00', name: '1pm', shift: 'day' , isSwingShift: false},
    { time: 'time-0115PM', slotName: '1:15 PM', timeInput: '13:15:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-0130PM', slotName: '1:30 PM', timeInput: '13:30:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-0145PM', slotName: '1:45 PM', timeInput: '13:45:00', name: '', shift: 'day' , isSwingShift: false},
    { time: 'time-0200PM', slotName: '2:00 PM', timeInput: '14:00:00', name: '2pm', shift: 'day' , isSwingShift: true},
    { time: 'time-0215PM', slotName: '2:15 PM', timeInput: '14:15:00', name: '', shift: 'day' , isSwingShift: true},
    { time: 'time-0230PM', slotName: '2:30 PM', timeInput: '14:30:00', name: '', shift: 'day' , isSwingShift: true},
    { time: 'time-0245PM', slotName: '2:45 PM', timeInput: '14:45:00', name: '', shift: 'day' , isSwingShift: true},
    { time: 'time-0300PM', slotName: '3:00 PM', timeInput: '15:00:00', name: '3pm', shift: 'day' , isSwingShift: true},
    { time: 'time-0315PM', slotName: '3:15 PM', timeInput: '15:15:00', name: '', shift: 'day' , isSwingShift: true},
    { time: 'time-0330PM', slotName: '3:30 PM', timeInput: '15:30:00', name: '', shift: 'day' , isSwingShift: true},
    { time: 'time-0345PM', slotName: '3:45 PM', timeInput: '15:45:00', name: '', shift: 'day' , isSwingShift: true},
    { time: 'time-0400PM', slotName: '4:00 PM', timeInput: '16:00:00', name: '4pm', shift: 'day' , isSwingShift: true},
    { time: 'time-0415PM', slotName: '4:15 PM', timeInput: '16:15:00', name: '', shift: 'day' , isSwingShift: true},
    { time: 'time-0430PM', slotName: '4:30 PM', timeInput: '16:30:00', name: '', shift: 'day' , isSwingShift: true},
    { time: 'time-0445PM', slotName: '4:45 PM', timeInput: '16:45:00', name: '', shift: 'day' , isSwingShift: true},
    { time: 'time-0500PM', slotName: '5:00 PM', timeInput: '17:00:00', name: '5pm', shift: 'day' , isSwingShift: true},
    { time: 'time-0515PM', slotName: '5:15 PM', timeInput: '17:15:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0530PM', slotName: '5:30 PM', timeInput: '17:30:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0545PM', slotName: '5:45 PM', timeInput: '17:45:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0600PM', slotName: '6:00 PM', timeInput: '18:00:00', name: '6pm', shift: 'night' , isSwingShift: true},
    { time: 'time-0615PM', slotName: '6:15 PM', timeInput: '18:15:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0630PM', slotName: '6:30 PM', timeInput: '18:30:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0645PM', slotName: '6:45 PM', timeInput: '18:45:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0700PM', slotName: '7:00 PM', timeInput: '19:00:00', name: '7pm', shift: 'night' , isSwingShift: true},
    { time: 'time-0715PM', slotName: '7:15 PM', timeInput: '19:15:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0730PM', slotName: '7:30 PM', timeInput: '19:30:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0745PM', slotName: '7:45 PM', timeInput: '19:45:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0800PM', slotName: '8:00 PM', timeInput: '20:00:00', name: '8pm', shift: 'night' , isSwingShift: true},
    { time: 'time-0815PM', slotName: '8:15 PM', timeInput: '20:15:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0830PM', slotName: '8:30 PM', timeInput: '20:30:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0845PM', slotName: '8:45 PM', timeInput: '20:45:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0900PM', slotName: '9:00 PM', timeInput: '21:00:00', name: '9pm', shift: 'night' , isSwingShift: true},
    { time: 'time-0915PM', slotName: '9:15 PM', timeInput: '21:15:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0930PM', slotName: '9:30 PM', timeInput: '21:30:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-0945PM', slotName: '9:45 PM', timeInput: '21:45:00', name: '', shift: 'night' , isSwingShift: true},
    { time: 'time-1000PM', slotName: '10:00 PM', timeInput: '22:00:00', name: '10pm', shift: 'night' , isSwingShift: true},
    { time: 'time-1015PM', slotName: '10:15 PM', timeInput: '22:15:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-1030PM', slotName: '10:30 PM', timeInput: '22:30:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-1045PM', slotName: '10:45 PM', timeInput: '22:45:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-1100PM', slotName: '11:00 PM', timeInput: '23:00:00', name: '11pm', shift: 'night' , isSwingShift: false},
    { time: 'time-1115PM', slotName: '11:15 PM', timeInput: '23:15:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-1130PM', slotName: '11:30 PM', timeInput: '23:30:00', name: '', shift: 'night' , isSwingShift: false},
    { time: 'time-1145PM', slotName: '11:45 PM', timeInput: '23:45:00', name: '', shift: 'night' , isSwingShift: false},
];
