import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { LocationSummary } from '../models/locations.interface';
import {from} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseService {

  public index(): Observable<any> {

    const route = `${this.apiUrl}/Location`;

    return this.http.get<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getInventoryLocations() {
    const route = `${this.apiUrl}/Location/InventoryLocations`;
    return this.http.get<LocationSummary[]>(route, this.headers());
  }

  public getBranchForLocation(locationId: string) {
    const branchIdPromise = this.getInventoryLocations()
      .pipe(map(locations => locations.find(location => location.id === locationId).branchId));
    return from(branchIdPromise);
  }



  public create(model: any): Observable<any> {

    const route = `${this.apiUrl}/Location/Create`;
    const body = JSON.stringify(model);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: any): Observable<any> {

    const route = `${this.apiUrl}/Location/Update`;
    const body = JSON.stringify(model);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getByName(name: string): Observable<any> {

    const route = `${this.apiUrl}/Location/GetByName/${name}`;
    const body = JSON.stringify(name);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
