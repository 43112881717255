import { Injectable } from "@angular/core";
import { BaseService } from "../../helpers/base.service";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import {
  Patient,
  PatientList,
  MedicalHistorySummary,
  SendMedicalHistory,
  PatientMedicalRecord,
  PatientMedicalRecordsPaginated,
  MedicalHistoryMedicalNote,
  MedicalHistoryMedication,
} from "../models/patient.interface";
import { MedicalRecordFilters } from "../patient-record/medical-history/medical-history.component";

@Injectable({
  providedIn: "root",
})
export class PatientService extends BaseService {
  public getPatient(patientId: string): Observable<Patient> {
    if (!this.isConnected) {
      return;
    }

    const route = `${this.apiUrl}/Patient/${patientId}`;

    return this.http.get<Patient>(route, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public checkHasWhiteboard(patientId: string): Observable<boolean> {
    const route = `${this.apiUrl}/Patient/${patientId}/CheckHasWhiteboard`;

    return this.http.get<boolean>(route, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getPatientPDF(patientId: string): any {
    const route = `${this.apiUrl}/Patient/${patientId}/GetPatientPDF`;
    return this.http.get(route, this.fileHeaders()).pipe(
      map((res) => {
        return new Blob([res], { type: "application/pdf" });
      })
    );
  }

  public getPatientRecordSummary(patientId: string): Observable<MedicalHistorySummary> {
    if (!this.isConnected) {
      return;
    }

    const route = `${this.apiUrl}/Patient/${patientId}/RecordSummary`;

    return this.http.get<MedicalHistorySummary>(route, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public getPatientRecord(patientId: string, pageNumber: number, pageSize: number, filters: MedicalRecordFilters):
      Observable<PatientMedicalRecordsPaginated> {
    if (!this.isConnected) {
      return;
    }

    const route = `${this.apiUrl}/Patient/${patientId}/Record/${pageNumber}/${pageSize}`;
    const body = JSON.stringify(filters);

    return this.http.post(route, body, this.headers()).pipe(
      map((response: any) => {
        const pageResults = new PatientMedicalRecordsPaginated();
        pageResults.count = response.count;

        pageResults.patientMedicalRecords = response.patientMedicalRecords.map((recordJson: any) => {
          let model = recordJson as PatientMedicalRecord;
          // Cast to a more detailed model based on the record type
          switch (model.type) {
            case 'Medical Note':
              model = recordJson as MedicalHistoryMedicalNote;
              break;
            case 'Medication':
              model = recordJson as MedicalHistoryMedication;
              break;
          }
          return model;
        });

        return pageResults;
      })
    );
  }

  public searchPatients(searchString: string): Observable<Patient[]> {
    if (!this.isConnected) {
      return;
    }
    const body = searchString;
    const route = `${this.apiUrl}/Patient/SearchPatients/${searchString}`;

    return this.http.get<Patient[]>(route, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public getPatientsByClientId(clientId: string): Observable<PatientList> {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Patient/GetByClient/${clientId}`;

    return this.http.get<PatientList>(route, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }
  public mergePatients(firstPatient: string, secondPatient: string) {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Patient/${firstPatient}/Merge/${secondPatient}`;

    return this.http.post(route, null, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public hideFromClient(patientId: string) {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Patient/${patientId}/HideFromClient`;

    return this.http.post(route, null, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public showToClient(patientId: string) {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Patient/${patientId}/ShowToClient`;

    return this.http.post(route, null, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public transferPatient(patientId: string, clientId: string) {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Patient/${patientId}/Transfer/${clientId}`;

    return this.http.post(route, null, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public getPatientSummaryByClientId(clientId: string): Observable<Patient[]> {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Patient/GetSummaryByClient/${clientId}`;

    return this.http.get<Patient[]>(route, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public uploadPatientImage(
    patientId: string,
    fileToUpload: File
  ): Observable<any> {
    const route = `${this.apiUrl}/patient/${patientId}/UploadPatientImage`;

    let formData: FormData = new FormData();
    formData.append("fileKey", fileToUpload, fileToUpload.name);


    return this.http.post<any>(route, formData).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public sendMedicalRecords(
    medicalHistory: SendMedicalHistory,
    patientId: string
  ): Observable<any> {
    const route = `${this.apiUrl}/patient/${patientId}/SendMedicalRecords`;

    return this.http.post(route, medicalHistory, this.fileHeaders()).pipe(
      map((res) => {
        return new Blob([res], { type: "application/pdf" });
      })
    );
  }

  public printMedicalRecords(
    medicalHistory: SendMedicalHistory,
    patientId: string
  ): Observable<any> {
    const route = `${this.apiUrl}/patient/${patientId}/PrintMedicalRecords`;

    return this.http.post(route, medicalHistory, this.fileHeaders()).pipe(
      map((res) => {
        return new Blob([res], { type: "application/pdf" });
      })
    );
  }

  public createPatient(
    createPatientModel: Patient,
    clientId: string
  ): Observable<any> {
    const route = `${this.apiUrl}/patient/${clientId}`;
    createPatientModel.clientId = clientId;
    const body = createPatientModel;


    return this.http.post<any>(route, body, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public updatePatient(patient: Patient): Observable<any> {
    const route = `${this.apiUrl}/patient/Update`;
    const body = patient;

    return this.http.post<Patient>(route, body, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public euthenize(patientId: string): Observable<any> {
    const route = `${this.apiUrl}/patient/${patientId}/Euthenize`;

    return this.http.post<any>(route, null, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public deleteMedicalNote(patientId: string, medicalNoteId: string): Observable<any> {
    const route = `${this.apiUrl}/patient/${patientId}/DeleteMedicalNote/${medicalNoteId}`;
    return this.http.post<any>(route, null, this.headers()).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
