import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LineItemRevisionModel, LogPage, LogViewModel, SystemLog } from '../models/logs.models';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from '../../helpers/offline-detection';
import { ConfigLoaderService } from '../../config-loader.service';

@Injectable({
  providedIn: 'root',
})
export class LogsService extends BaseService {
  constructor(
    http: HttpClient,
    connectionService: ConnectionService,
    configService: ConfigLoaderService
  ) {
    super(http, connectionService, configService);
  }

  public index(page: LogPage): Observable<LogViewModel> {
    const route = `${this.apiUrl}/Log`;
    const body = JSON.stringify(page);
    return this.http.post<LogViewModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public itemLogs(page: LogPage): Observable<SystemLog[]> {
    const route = `${this.apiUrl}/Log/ItemLogs`;
    const body = JSON.stringify(page);
    return this.http.post<SystemLog[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public lineItemRevision(invoiceId: string): Observable<LineItemRevisionModel[]> {
    const route = `${this.apiUrl}/Log/LineItemRevision`;
    return this.http.post<LineItemRevisionModel[]>(route, JSON.stringify(invoiceId), this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public log(message: string, parameters: { [key: string]: any } = {}): Observable<void> {
    console.log('logging message', message, parameters);
    const route = `${this.apiUrl}/Log/LogMessage`;
    const body = JSON.stringify({
      message,
      parameters
    });
    return this.http.post<void>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
