import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {EmailViewModel, InputResults} from 'src/app/configuration-management/models/email.interface';
import {MatDialog, MatSnackBar} from '@angular/material';
import {MedicalNoteTemplateService} from 'src/app/configuration-management/services/medical-note-template.service';
import {Patient, PatientMedicalRecordBindingModel} from '../../../patient-management/models/patient.interface';
import {Observable, Subscription} from 'rxjs';


@Component({
    selector: 'app-medical-note',
    templateUrl: './medical-note.component.html',
    styleUrls: ['./medical-note.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MedicalNoteComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() selectedEmail: PatientMedicalRecordBindingModel;
    @Input() selectedPatient: Patient;
    @Input() saveForm: boolean;
    @Input() selectedDate: Date;
    @Output() sendEmail = new EventEmitter<PatientMedicalRecordBindingModel>();
    @Input() updateDate: Observable<Date>;
    @Output() fetchingData = new EventEmitter<boolean>();
    private eventsSubscription: Subscription;

    inputResults: InputResults[] = [];

    constructor(
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private medicalNoteTemplateService: MedicalNoteTemplateService,
    ) { }

    ngOnInit() {
        if (this.updateDate != null) {
            this.eventsSubscription = this.updateDate.subscribe((date) => {
                this.selectedDate = date;
                this.replace();
            });
        }
    }

    ngAfterViewInit() {
        if (this.selectedEmail.id && this.selectedEmail.inputResults !== undefined && this.selectedEmail.inputResults.length > 0) {
            this.inputResults = this.selectedEmail.inputResults;
            this.restoreInputs();
        } else {
            this.getMedicalNoteTemplate();
        }
    }

    restoreInputs() {
        const shadowRoot = document.querySelector('app-medical-note').shadowRoot;
        const container = shadowRoot.getElementById('container');
        const inputs = container.getElementsByClassName('input');

        const containerCopy = shadowRoot.getElementById('container2');
        containerCopy.innerHTML = container.innerHTML;

        for (let index = 0; index < inputs.length; index++) {
          if (this.inputResults[index].value.match('^\{.+\}$')) {
            // Medical Record assignees is saving the template in the input spot, keep from overwriting the HTML body with a template
            break;
          }
          (inputs[index] as HTMLTextAreaElement).value = this.inputResults[index].value;
        }

        setTimeout(() => {
            this.replace();
        });
    }

    pasteEvent() {
        setTimeout(() => {
            this.replace();
        });
    }

    getMedicalNoteTemplate() {
        if (!this.selectedPatient && this.selectedEmail.id && this.selectedEmail.inputResults.length > 0) {
            return;
        }

        this.fetchingData.emit(true);
        this.medicalNoteTemplateService.getMedicalNoteTemplate( this.selectedPatient.id, this.selectedEmail.id)
            .subscribe(res => {
                this.selectedEmail.htmlBody = res.htmlBody;
                this.selectedEmail.textBody = res.textBody;

                setTimeout(() => {
                    this.replace();
                });
            })
            .add(() => {
              this.fetchingData.emit(false);
            });
      }


    replace() {
        const shadowRoot = document.querySelector('app-medical-note').shadowRoot;
        const container = shadowRoot.getElementById('container');

        if (this.selectedDate != null) {
            const dateElement = shadowRoot.getElementById('date');
            dateElement.innerHTML = new Date(this.selectedDate).toDateString();

            const dateElements = container.getElementsByClassName('date');

            // tslint:disable-next-line:prefer-for-of no iterator in dateElements
            for (let index = 0; index < dateElements.length; index++) {
              dateElements[index].innerHTML = new Date(this.selectedDate).toDateString();
            }
        }

        const inputs = container.getElementsByClassName('input');
        const containerCopy = shadowRoot.getElementById('container2');
        containerCopy.innerHTML = container.innerHTML;
        const inputsCopy = containerCopy.getElementsByClassName('input');

        for (let index = 0; index < inputs.length; index++) {
          (inputsCopy[index] as HTMLTextAreaElement).value = (inputs[index] as HTMLTextAreaElement).value;
        }

        while (inputsCopy.length > 0) {

            inputsCopy[0].replaceWith(`${(inputsCopy[0] as HTMLTextAreaElement).value}`);
        }

        this.inputResults = [];
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < inputs.length; index++) {
            const inputResult = {
                value: (inputs[index] as HTMLTextAreaElement).value,
                identifier: inputs[index].id
            } as InputResults;

            this.inputResults.push(inputResult);
        }

        this.selectedEmail.textBody = containerCopy.innerHTML;
        this.selectedEmail.inputResults = this.inputResults;
        this.sendEmail.emit(this.selectedEmail);
    }

    ngOnDestroy() {
        if (this.updateDate != null) {
            this.eventsSubscription.unsubscribe();
        }
    }
}
