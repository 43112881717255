import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BranchService } from '../../configuration-management/services/branch.service';
import { Branch } from '../../configuration-management/models/branch.interface';

@Component({
  selector: 'app-branch-filter',
  templateUrl: './branch-filter.html',
  styleUrls: ['./branch-filter.scss']
})
export class BranchFilter implements OnInit {

  @Input() placeholder: string;
  @Input() overrideBranches: string[];
  @Output() outputBranches = new EventEmitter<Branch[]>();

  branches: Branch[];
  branchesChosen: Branch[] = [];

  constructor(
    private branchService: BranchService
  ) { }


  ngOnInit() {
    this.getBranches();
  }

  getBranches() {
    this.branchService.index().subscribe(res => {
      this.branches = res.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  chooseBranches(branchList: Branch[]) {
    this.branchesChosen = branchList;
    this.outputBranches.emit(branchList);
  }

  clearBranchesFromFilter() {
    this.chooseBranches([]);
  }
}
