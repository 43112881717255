import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LineItemInputService} from '../../../line-item-entry/services/line-item-input.service';
import {LineItem} from 'src/app/line-item-entry/models/line-item.interface';
import {SearchService} from '../search-service';
import {Subscription} from 'rxjs';
import {SearchLineItemDataService} from '../data-services/search-line-item-data.service';


@Component({
  selector: 'app-search-line-items',
  templateUrl: './search-line-items.component.html',
  styleUrls: ['./search-line-items.component.scss'],
  providers: [SearchLineItemDataService]
})
export class SearchLineItemsComponent implements OnInit, OnDestroy {

  @Input() lineItem: LineItem;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() billingOnly = false;
  @Output() selectedLineItem = new EventEmitter<LineItem>();

  lineItemSearch = new FormControl({ value: '', disabled: this.disabled });
  filteredOptions: LineItem[] = [];

  subscriptions: Subscription;


  constructor(
    public lineItemInputService: LineItemInputService,
    public lineItemDataService: SearchLineItemDataService,
    public searchService: SearchService
  ) { }

  ngOnInit() {
    if (this.lineItem) {
      this.lineItemSearch = new FormControl({ value: this.lineItem.name, disabled: this.disabled });
    }

    this.subscriptions = this.lineItemSearch.valueChanges.subscribe(value => this.lineItemDataService.input(value, this.billingOnly));
    this.subscriptions.add(this.lineItemDataService.fetch.dataReceived.subscribe(this.setLineItems.bind(this)));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.lineItemDataService.dispose();
  }

  setLineItems(results: LineItem[]) {
    if (results) {
      this.filteredOptions = results;
    }
  }


  selectLineItem(lineItem: LineItem) {
    const currentLineItemName = lineItem.name;
    this.lineItemSearch.setValue(currentLineItemName);
    this.lineItem = lineItem;
    this.selectedLineItem.emit(lineItem);
  }
}
