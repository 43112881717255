import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';
import {
  EventInfo, CalendarEvent, addMinutes, CalendarEventTypes,
  GoogleLocation, CalendarEventClient, CalendarEventPatient, CalendarEventUser, CalendarEventEquipment, TIMESLOTS, TimeSlot, EventInfoData
} from '../../models/schedule.models';
import { Branch } from 'src/app/configuration-management/models/branch.interface';
import { BranchService } from 'src/app/configuration-management/services/branch.service';
import { AccountService } from '../../../configuration-management/services/account.service';
import { UserAccount } from '../../../configuration-management/models/user.interface';
import { EquipmentService } from 'src/app/configuration-management/services/equipment.service';
import {EquipmentSummary, StatusToLabelMapping} from '../../../configuration-management/models/equipment.interface';
import { Note } from 'src/app/shared/notes/note.model';
import { CalendarService } from '../../services/calendar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Client } from 'src/app/client-management/models/client.interface';
import { Patient } from 'src/app/patient-management/models/patient.interface';
import { ReferringDoctor } from '../../../configuration-management/models/referring-doctor.interface';
import { ReferringDoctorService } from '../../../configuration-management/services/referring-doctor.service';
import { GoogleDistance } from '../../models/google-location.models';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation.dialog';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ItemLogDialogComponent } from '../../../shared/item-log-dialog/item-log.dialog';
import { LogPage } from '../../../configuration-management/models/logs.models';
import { AddressGateCodesService } from '../../services/addressGateCodes.service';
import {FormControl} from '@angular/forms';
import PlaceResult = google.maps.places.PlaceResult;
import {CreateBeforeAfter} from "../../../shared/appointment-search/appointment-search.model";



@Component({
  selector: 'app-appointment-view',
  templateUrl: 'appointment-view.component.html',
  styleUrls: ['appointment-view.component.scss'],
})
export class AppointmentViewComponent implements OnInit, OnDestroy {

  eventInfo: EventInfo;
  branches: Branch[];
  calendarEventTypes = CalendarEventTypes;
  doctors: UserAccount[];
  searchCtrl = null;
  searchCtrl2 = null;
  techs: UserAccount[];
  referringDoctors: ReferringDoctor[];
  equipments: EquipmentSummary[];
  equipmentStatusToLabelMapping = StatusToLabelMapping;
  equipmentSearchCtrl = new FormControl(null);
  resetClient = '';
  showSummary = false;
  distance: GoogleDistance = null;
  fromAddress: GoogleLocation;
  fillerAppointment: CalendarEvent;
  timeSlots = TIMESLOTS;
  startTimeSlots = TIMESLOTS;
  endTimeSlots = TIMESLOTS;
  hours = [0.0, 0.25, 0.50, 0.75, 1.00, 1.25, 1.50, 1.75, 2.00, 2.25, 2.50, 2.75,
    3.00, 3.25, 3.50, 3.75, 4.00, 4.25, 4.50, 4.75, 5.00, 5.25, 5.50, 5.75, 6.00,
    6.25, 6.50, 6.75, 7.00, 7.25, 7.50, 7.75, 8.00, 8.25, 8.50, 8.75, 9.00, 9.25,
    9.50, 9.75, 10.00, 10.25, 10.50, 10.75, 11.00, 11.25, 11.50, 11.75, 12.00,
    12.25, 12.50, 12.75, 13.00, 13.25, 13.50, 13.75, 14.00, 14.25, 14.50, 14.75,
    15.00, 15.25, 15.50, 15.75, 16.00, 16.25, 16.50, 16.75, 17.00, 17.25, 17.50,
    17.75, 18.00, 18.25, 18.50, 18.75, 19.00, 19.25, 19.50, 19.75, 20.00, 20.25,
    20.50, 20.75, 21.00, 21.25, 21.50, 21.75, 22.00, 22.25, 22.50, 22.75, 23.00,
    23.25, 23.50, 23.75];
  subscription: Subscription;
  constructor(
    public branchService: BranchService,
    public accountService: AccountService,
    public referringDoctorService: ReferringDoctorService,
    public equipmentService: EquipmentService,
    public calendarService: CalendarService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<any>,
    public addressGateCodesService: AddressGateCodesService,
    @Inject(MAT_DIALOG_DATA) public data: EventInfoData,
  ) { }

  ngOnInit() {

    this.eventInfo = this.data.eventInfo;

    this.getBranches();
    this.getDoctors();
    this.getEquipment();
    this.getTechs();
    this.getReferringDoctors();


    if (this.eventInfo.calendarEvent) {
      this.setupViewExistingAppointment();
    } else if (this.eventInfo.fromExistingAppointment) {
      this.setupNewApptFromExisting();
    } else {
      this.setupNewAppointment();
    }

  }

  private prepareClientFromData() {
    const patient = new CalendarEventPatient();
    patient.patient = this.eventInfo.patient != null ? this.eventInfo.patient : new Patient();

    const client = new CalendarEventClient();
    client.patients = [patient];
    client.client = this.eventInfo.client != null ? this.eventInfo.client : new Client();

    return client;
  }

  private setupNewApptFromExisting() {
    const defaultDurationHours = 1;
    const defaultDurationInterval = defaultDurationHours * 4; // 15 min slots

    let startTimeHours: string;
    let endTimeHours: string;
    switch (this.eventInfo.existingAppointmentFilters.createBeforeAfter) {
      case CreateBeforeAfter.before:
        startTimeHours = this.timeSlots[this.eventInfo.fromExistingAppointment.startTimeHoursEnum - defaultDurationInterval].timeInput;
        endTimeHours = this.timeSlots[this.eventInfo.fromExistingAppointment.startTimeHoursEnum].timeInput;
        break;
      case CreateBeforeAfter.after:
        startTimeHours = this.timeSlots[this.eventInfo.fromExistingAppointment.endTimeHoursEnum].timeInput;
        endTimeHours = this.timeSlots[this.eventInfo.fromExistingAppointment.endTimeHoursEnum + defaultDurationInterval].timeInput;
        break;
      default:
        throw Error('Missing param on event creation');
    }

    const location = this.eventInfo.existingAppointmentFilters.location
      ? this.eventInfo.existingAppointmentFilters.location
      : new GoogleLocation();

    this.eventInfo.calendarEvent = {
      startTimeHours,
      date: this.eventInfo.fromExistingAppointment.date,
      endTimeHours,
      time: defaultDurationHours,
      isSingle: true,
      equipmentIsNeeded: false,
      googleLocation: location,
      notes: [],
      doctors: this.eventInfo.fromExistingAppointment.doctors,
      techs: [new CalendarEventUser()],
      clients: [this.prepareClientFromData()],
      branch: new Branch(),
      referringDoctor: new ReferringDoctor(),
      calendarEventType: 'Block',
      clientIndex: 1,
      equipment: [new CalendarEventEquipment()]
    } as CalendarEvent;

    this.eventInfo.calendarEvent.doctors.forEach(doctor => {
      doctor.id = null;
    });
  }

  private setupNewAppointment() {
    const startTimeIndex = this.timeSlots.findIndex(x => this.eventInfo.timeSlot.timeInput === x.timeInput);
    const endTimeHours = this.timeSlots[startTimeIndex + 2].timeInput;
    const startTimeHours = this.eventInfo.timeSlot.timeInput as any;
    const time = moment(new Date('1970/01/01 ' + endTimeHours))
      .diff(moment(new Date('1970/01/01 ' + startTimeHours)), 'hours', true);

    const doctor = { user: this.eventInfo.doctor } as CalendarEventUser;

    this.eventInfo.calendarEvent = {
      startTimeHours,
      date: this.eventInfo.date,
      endTimeHours,
      time,
      isSingle: true,
      equipmentIsNeeded: false,
      googleLocation: new GoogleLocation(),
      notes: [],
      doctors: [doctor],
      techs: [new CalendarEventUser()],
      clients: [this.prepareClientFromData()],
      branch: new Branch(),
      referringDoctor: new ReferringDoctor(),
      calendarEventType: 'Block',
      clientIndex: 1,
      equipment: [new CalendarEventEquipment()]
    } as CalendarEvent;
  }

  private setupViewExistingAppointment() {
    this.eventInfo.calendarEvent = JSON.parse(JSON.stringify(this.eventInfo.calendarEvent));

    console.log(this.eventInfo.calendarEvent.googleLocation.latitude);
    // Fix branch
    if (this.eventInfo.calendarEvent.branch == null) {
      this.eventInfo.calendarEvent.branch = new Branch();
    }

    // Fix Ref Doc
    if (this.eventInfo.calendarEvent.referringDoctor == null) {
      this.eventInfo.calendarEvent.referringDoctor = new ReferringDoctor();
    }

    // Fix techs
    if (this.eventInfo.calendarEvent.techs.length === 0) {
      this.eventInfo.calendarEvent.techs = [new CalendarEventUser()];
    }

    // Fix equipment
    if (this.eventInfo.calendarEvent.equipment.length === 0) {
      this.eventInfo.calendarEvent.equipment = [new CalendarEventEquipment()];
    }

    // Fix clients
    if (this.eventInfo.calendarEvent.clients.length === 0) {
      const client = new CalendarEventClient();
      client.patients = [new CalendarEventPatient()];
      client.client = new Client();
      this.eventInfo.calendarEvent.clients = [client];
    }

    // Fix Patients

    if (this.eventInfo.calendarEvent.clients.length > 0) {
      this.eventInfo.calendarEvent.clients.forEach(client => {
        if (client.patients.length === 0) {
          client.patients = [new CalendarEventPatient()];
        }
      });
    }

    this.showSummary = true;

    const time = moment(new Date('1970/01/01 ' + this.eventInfo.calendarEvent.endTimeHours))
      .diff(moment(new Date('1970/01/01 ' + this.eventInfo.calendarEvent.startTimeHours)), 'hours', true);
    this.eventInfo.calendarEvent.time = time;

    this.checkConflicts();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkIn() {
    this.eventInfo.calendarEvent.checkedIn = true;
    this.calendarService.checkIn(this.eventInfo.calendarEvent.id).subscribe(x => {
      this.snackBar.open('Patient Checked In', 'Success', {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

  checkOut() {
    this.eventInfo.calendarEvent.checkedIn = false;
    this.calendarService.checkOut(this.eventInfo.calendarEvent.id).subscribe(x => {
      this.snackBar.open('Patient Checked Out', 'Success', {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }
  // Adding and removing clients, patients, dctors, techs, equipment
  addPatient(client: CalendarEventClient) {
    client.patients.push(new CalendarEventPatient());
  }

  removePatient(patientIndex, client) {
    const patient = client.patients[patientIndex];
    client.patients = client.patients.filter(x => x !== patient);
  }

  addDoctor() {
    this.eventInfo.calendarEvent.doctors.push(new CalendarEventUser());
  }

  removeDoctor(index) {
    const doctor = this.eventInfo.calendarEvent.doctors[index];
    this.eventInfo.calendarEvent.doctors = this.eventInfo.calendarEvent.doctors.filter(x => x !== doctor);
  }

  addTech() {
    this.eventInfo.calendarEvent.techs.push(new CalendarEventUser());
  }

  removeTech(index) {
    const tech = this.eventInfo.calendarEvent.techs[index];
    this.eventInfo.calendarEvent.techs = this.eventInfo.calendarEvent.techs.filter(x => x !== tech);
  }

  addEquipment() {
    this.eventInfo.calendarEvent.equipment.push(new CalendarEventEquipment());
  }

  removeEquipment(index) {
    const equipment = this.eventInfo.calendarEvent.equipment[index];
    this.eventInfo.calendarEvent.equipment = this.eventInfo.calendarEvent.equipment.filter(x => x !== equipment);
  }


  eventType(eventType: string) {
    if (eventType === '') {
      return '';
    }

    return this.calendarEventTypes.find(x => x.name === eventType).displayName;
  }

  // Setting Client and Patient logic
  setClient(calendarClient: CalendarEventClient, event: Client) {

    if (calendarClient.client.id === '' || calendarClient.client.id !== event.id) {
      calendarClient.patients = [new CalendarEventPatient()];
      calendarClient.client = event;
    }
    this.checkConflicts();

  }

    // Go to link
    goToLink(url: string) {
      if (url !== '') {
        window.open(url, '_blank');
      }
  }

  setPatient(calendarPatient: CalendarEventPatient, event: Patient, calendarClient: CalendarEventClient, clientIndex) {

    if (calendarClient.client.id === '') {

      const client = new Client();
      client.id = event.clientId;
      client.name = event.clientName;
      calendarClient.client = client;
      calendarPatient.patient = event;

    } else if (calendarClient.client.id === event.clientId) {
      calendarPatient.patient = event;
    }

    this.resetClient = clientIndex;
    setTimeout(() => {
      this.resetClient = '';
    });
    this.checkConflicts();
  }

  addClient() {

    const client = new CalendarEventClient();
    client.patients = [new CalendarEventPatient()];
    client.client = new Client();
    this.eventInfo.calendarEvent.clients.push(client);


  }

  removeClient(clientIndex) {
    const client = this.eventInfo.calendarEvent.clients[clientIndex];
    this.eventInfo.calendarEvent.clients = this.eventInfo.calendarEvent.clients.filter(x => x !== client);
    this.checkConflicts();
  }

  getNumber(x) {
    return new Array(x);
  }



  getDistance() {
    console.log('Travel Time');
    console.log(this.fromAddress);

    if (
      (this.fromAddress === null || this.fromAddress === undefined)
      || this.fromAddress.placeId === ''
      || this.eventInfo.calendarEvent.googleLocation.placeId === ''
    ) {
      return;
    }
    console.log('Travel Time');
    this.calendarService.calculateTravelTime(this.eventInfo.calendarEvent.googleLocation.placeId, this.fromAddress.placeId)
      .subscribe(res => {
      this.distance = res;
    });

  }

  onAutocompleteFrom(place: PlaceResult) {

    this.fromAddress = new GoogleLocation(place);

    this.getDistance();
  }

  onAutocompleteSelected(place: PlaceResult) {

    this.eventInfo.calendarEvent.googleLocation = new GoogleLocation(place);

    this.getDistance();
    this.getGateCodes();
  }

  getGateCodes() {

    if (this.eventInfo.calendarEvent.googleLocation.placeId === '') {
      return;
    }
    this.addressGateCodesService.findGateCodes(this.eventInfo.calendarEvent.googleLocation.placeId)
      .subscribe(res => {
        this.eventInfo.calendarEvent.googleLocation.gateCodes = res;
    });
  }

  setBranch(branch: Branch) {
    this.eventInfo.calendarEvent.branch = branch;
  }

  setDoctor( userAccount: UserAccount ) {
    this.eventInfo.calendarEvent.doctors.push({
      user: userAccount
    } as CalendarEventUser);
  }

  createFillerAppointment() {

    const client = new CalendarEventClient();
    client.patients = [new CalendarEventPatient()];
    client.client = new Client();
    this.fillerAppointment = JSON.parse(JSON.stringify(this.eventInfo.calendarEvent));
    this.fillerAppointment.calendarEventType = 'Block';
    this.fillerAppointment.clients = [client];
    this.fillerAppointment.googleLocation = new GoogleLocation();

    const doctors = [];
    const techs = [];
    const equipments = [];

    this.eventInfo.calendarEvent.equipment.forEach(x => {
      equipments.push({ equipment: x.equipment } as CalendarEventEquipment);
    });
    this.eventInfo.calendarEvent.doctors.forEach(x => {
      doctors.push({ user: x.user } as CalendarEventUser);
    });
    this.eventInfo.calendarEvent.techs.forEach(x => {
      techs.push({ user: x.user } as CalendarEventUser);
    });
    this.fillerAppointment.techs = [new CalendarEventUser()];

    this.fillerAppointment.equipment = equipments;
    this.fillerAppointment.techs = techs;
    this.fillerAppointment.doctors = doctors;
    this.fillerAppointment.endTime = this.fillerAppointment.startName;
    this.fillerAppointment.endTimeHours = this.fillerAppointment.startTimeHours;
    this.fillerAppointment.endName = this.fillerAppointment.startName;
    this.fillerAppointment.id = null;


    let time = this.distance.rows[0].elements[0].duration.value;
    time = (Math.ceil((time / 60) / 15) * 15);
    this.fillerAppointment.startTimeHours = addMinutes(this.eventInfo.calendarEvent.startTimeHours, -time);


    this.calendarService.postEvent(this.fillerAppointment).subscribe(res => {
      this.snackBar.open('Filler Appointment Successfully Created', 'Success', {
        duration: 2000,
      });

    });
  }

  submitForm() {
    if (this.eventInfo.calendarEvent.startTimeHours === this.eventInfo.calendarEvent.endTimeHours) {
      this.snackBar.open('Appointment cannot have the same start and end time.', 'Error', {
        duration: 2000,
      });
      return;
    }
    // If we are on the client page, the selected doctor defaults to an empty UserAccount() object
    if (this.eventInfo.calendarEvent.doctors[0].user.id === undefined) {
      this.snackBar.open('You must select a doctor.', 'Error', {
        duration: 3000,
      });
      return;
    }
    this.calendarService.postEvent(this.eventInfo.calendarEvent).subscribe(res => {
      this.snackBar.open('Appointment Successfully Created/Updated', 'Success', {
        duration: 2000,
      });
      this.dialogRef.close(true);
    });
  }

  checkConflicts() {
    this.calendarService.findScheduleConflicts(this.eventInfo.calendarEvent).subscribe(res => {
      if (res.length !== 0) {
        this.eventInfo.calendarEvent.conflicts = res;
        this.snackBar.open('Scheduling Conflicts Found', 'Warning', {
          duration: 2000,
        });
      }

    });
  }

  updateNotes(normalNotes: Note[]) {
    this.eventInfo.calendarEvent.notes = normalNotes;
  }

  setTime(type) {

    const startTimeHours = this.eventInfo.calendarEvent.startTimeHours;
    const endTimeHours = this.eventInfo.calendarEvent.endTimeHours;

    const startTimeIndex = this.timeSlots.findIndex(x => this.eventInfo.calendarEvent.startTimeHours === x.timeInput);
    let endTimeIndex = this.timeSlots.findIndex(x => this.eventInfo.calendarEvent.endTimeHours === x.timeInput);

    let time = moment(new Date('1970/01/01 ' + endTimeHours))
      .diff(moment(new Date('1970/01/01 ' + startTimeHours)), 'hours', true);

    if (time < 0 && type === 'endTime') {
        this.eventInfo.calendarEvent.startTimeHours = this.timeSlots[endTimeIndex].timeInput;
    }

    // Regardless, recalculate the end time and window size based off of the change
    if (type === 'time' || type === 'startTime') {

      endTimeIndex = this.hours.findIndex(x => x === this.eventInfo.calendarEvent.time);

      if (this.timeSlots.length < startTimeIndex + endTimeIndex) {
        this.eventInfo.calendarEvent.endTimeHours = this.timeSlots[this.timeSlots.length - 1].timeInput;
      } else {
        this.eventInfo.calendarEvent.endTimeHours = this.timeSlots[startTimeIndex + endTimeIndex].timeInput;
      }
    }

    time = moment(new Date('1970/01/01 ' + this.eventInfo.calendarEvent.endTimeHours))
    .diff(moment(new Date('1970/01/01 ' + this.eventInfo.calendarEvent.startTimeHours)), 'hours', true);
    this.eventInfo.calendarEvent.time = time;


  }

  restoreAppointment() {
    this.calendarService.restoreEvent(this.eventInfo.calendarEvent.id).subscribe(x => {
      this.snackBar.open('Appointment Successfully Restored', 'Success', {
        duration: 2000,
      });

      this.dialogRef.close(true);
    });
  }

  cancelAppointment() {


    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {header: 'Cancel Appointment', body: 'You are about to cancel this appointment.'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.calendarService.cancelEvent(this.eventInfo.calendarEvent.id).subscribe(x => {
          this.snackBar.open('Appointment Successfully Removed', 'Success', {
            duration: 2000,
          });
          this.dialogRef.close(true);
        },
          error => {
            this.snackBar.open('Appointment Was Not Removed', 'Error', {
              duration: 2000,
            });
        });
      }
    });


  }

  getBranches() {
    this.branchService.index().subscribe(response => {
      this.branches = response;
    });
  }

  getDoctors() {
    this.subscription = this.accountService.getDoctors().subscribe(res => {
      this.doctors = res;
    });
  }

  getTechs() {
    this.accountService.getTechs().subscribe(res => {
      this.techs = res;
    });
  }

  getReferringDoctors() {
    this.referringDoctorService.index().subscribe(res => {
      this.referringDoctors = res;
    });
  }

  getEquipment() {
    this.equipmentService.index().subscribe(response => {
      this.equipments = response;
      this.equipments.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  routeToQuickEntry() {
    if ( this.eventInfo.calendarEvent.clients.length === 0) {
      this.router.navigateByUrl('quick-entry');
    } else {

      const client = this.eventInfo.calendarEvent.clients[0];

      if (client.client.id === '') {
        this.router.navigateByUrl('quick-entry');
        return;
      }

      if (client.patients.length > 1 || this.eventInfo.calendarEvent.clients.length > 1) {
        this.router.navigateByUrl(`quick-entry/grid/${this.eventInfo.calendarEvent.id}`);
      } else if (client.patients.length === 0) {
        this.router.navigateByUrl(`quick-entry/client/${client.client.id}`);
      } else {
        const patient = client.patients[0];

        if (patient.patient.id === '') {
          this.router.navigateByUrl(`quick-entry/client/${client.client.id}`);
          return;
        }
        this.router.navigateByUrl(`quick-entry/client/${client.client.id}/patient/${patient.patient.id}`);
      }

    }
  }


  setEventType(event) {
    const calEvent = this.calendarEventTypes.find(x => x.name === event);
    this.eventInfo.calendarEvent.time = calEvent.time;

    this.setTime('time');
  }


  send(type: string) {
    let sendDoctor = false;
    let sendClient = false;

    if (type === 'Doctor') {
      sendDoctor = true;
    }

    if (type === 'Client') {
      sendClient = true;
    }

    this.calendarService.sendConfirmation(this.eventInfo.calendarEvent.id, sendClient, sendDoctor).subscribe(x => {
      this.snackBar.open('Confirmation sent', 'Success', {
        duration: 2000,
      });

      if (type === 'Doctor') {
        this.eventInfo.calendarEvent.confirmationSent = true;
      }

      if (type === 'Client') {
        this.eventInfo.calendarEvent.confirmationSent = true;      }
    },
    error => {
      this.snackBar.open('Confirmation was not sent', 'Error', {
        duration: 2000,
      });
    });
  }


  viewLogs() {
    const logPage = new LogPage();
    logPage.itemId = this.eventInfo.calendarEvent.id;
    logPage.logType = 'Calendar';

    const dialogRef = this.dialog.open(ItemLogDialogComponent, {
      height: 'auto',
      width: 'auto',
      panelClass: 'log-panel',
      data: logPage
    });
  }
}



