import { LineItem } from './line-item.interface';


export class InvoiceBase {
  lineItems: LineItem[];
  client: InvoiceClient;
  patient: InvoicePatient;
  doctor: InvoiceDoctor;
  id: string;
  approvedLineItems: number;
  lineItemCount: number;
  number: string;
  total: number;
  accountBalance: number;
  unfinishedBalance: number;
}

export class Invoice extends InvoiceBase {
    invoiceStatus: string;
    dwa: string;
    requireMedicalRecord: number;
    startDateRange: Date;
    endDateRange: Date;
    closed: boolean;
    whiteboardId: string;
}

export class MedicalRecordInvoice {
  id: string;
  number: string;
}

export interface InvoiceDoctor {
    name: string;
    number: string;
    id: string;
}
export interface InvoicePatient {
    name: string;
    number: string;
    hasEQProtect: boolean;
    id: string;
}
export interface InvoiceClient {
    name: string;
    number: string;
    address: string;
    paymentMethods: string;
    id: string;
}

export class RequestInvoices {
    patientId: string;
    clientId: string;
    userId: string;
    startDate: Date;
    endDate: Date;
    status: string;
    invoiceId: string;
    invoiceStatus: string;
    incompleteMedicalRecords: boolean;
}

export class RequestMedicalRecords {
  patientId: string;
  clientId: string;
  userId: string;
  startDate: Date;
  endDate: Date;
  status: string;
  medicalRecordStatus: string;
  branchId: string;
}

export interface ClientInvoice {
    dateRange: string;
    number: string;
    id: string;
}
